import * as Sentry from "@sentry/react";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import { ENV } from "./config/config";
import routes from "./routes/routes";
import { ListenMetaMaskAccs } from "./utilities/common.utility";
import PrivateRoute from "./utilities/privateRoute";

function App() {
    //ReactGA.initialize("G-ZDXW1C4QVY");
    //ReactGA.pageview(window.location.pathname + window.location.search);

    ListenMetaMaskAccs();

    Sentry.init({
        environment: ENV.chainId === 1101 ? "production" : "testnet",
        dsn: "https://a370f6ec11c74cf19377f2ae992704f0@o4504739879649280.ingest.sentry.io/4504739881222144",
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/backend\.owna\.io/, /^https:\/\/testnet-backend\.owna\.io/, /^https:\/\/polygon-backend\.owna\.io/],
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        tracesSampleRate: 1.0,
    });

    return (
        <>
            <>
                <Header />
                <Routes>
                    {routes.map((route, i) => {
                        return (
                            <Route
                                path={route.path}
                                key={i}
                                element={
                                    <PrivateRoute access={!!(route.access || ENV.getUserKeys("isAdmin")?.isAdmin)}>
                                        <route.component />
                                    </PrivateRoute>
                                }
                            />
                        );
                    })}{" "}
                </Routes>
            </>
        </>
    );
}

export default Sentry.withProfiler(App);
