import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {FiChevronDown} from "react-icons/fi";
import styles from "./nft-popup.module.css";

type Props = {
    volume: string;
    currency: string;
    apr: string;
    type: string;
    network: string;
};

const IdealDeal: React.FC<Props> = ({volume, currency, apr, type, network}) => {
    const conditions: Array<{ name: string, value: string }> = [
        {name: "Volume", value: volume},
        {name: "Currency", value: currency},
        {name: "APR", value: apr},
        {name: "Type", value: type},
        {name: "Network", value: network},
    ]
    return (

        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<FiChevronDown/>}
                aria-controls="nft-attributes-content"
                id="nft-attributes-header"
                className={styles.aboutAccordionHeader}
            >
                <Typography>Borrowed Preferred Condition</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="grid sm:grid-cols-3 grid-cols-2 gap-2 py-2">
                    {
                        conditions.map((item) => (
                            <div className={styles.aboutAttributeContainer}>
                                <div className={styles.aboutAttributeName}>{item.name}</div>
                                <div className={styles.aboutAttributeValue}>{item.value}</div>
                            </div>
                        ))
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default IdealDeal;
