import React from "react";
import Button from "../../Button/Button";
import styles from "./LendingDashboard.module.css";
import {toast} from "react-toastify";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";

interface LendingDashboardItemProps {
    data: any[];
    loading?: any;
    handleSellItem?: (data: any) => void;
    handleRefundItem?: (data: any) => void;
    borrow?: boolean;
    disabled?: boolean;
    claimFunds?: (data: any) => void;
    connect?: string;
    lender?: boolean;
}

const LendingDashboardItem =
    ({
         data,
         loading,
         handleSellItem,
         handleRefundItem,
         borrow,
         disabled,
         claimFunds
     }: LendingDashboardItemProps) => {
        const navigate = useNavigate();

        return (
            <div className={`${styles.wrapper} ${styles.wrapperBorder}`}>
                <div className={styles.dataWrapper}>
                    {data &&
                        data?.length > 0 &&
                        data.map(
                            (item: any, index: number) =>
                                !item?.data && (
                                    <div
                                        key={index}
                                        className={`${styles.textContainer} ${index % 2 === 0 ? styles.textContainerBorder : ""}`}
                                    >
                                        <div>
                                            <div className={styles.titleAndIcon}>
                                                {item.img && (
                                                    <img src={item.img} alt="#" className={styles.icon} onClick={() => { if (item.link) navigate(item.link); }} style={{ cursor: item.link ? "pointer" : "unset" }}/>
                                                )}
                                                {!item.btn && (
                                                    <div>
                                                        <p className={styles.title}>{item.title}</p>
                                                        {item?.copy ? (
                                                            <CopyToClipboard
                                                                onCopy={() =>
                                                                    toast.success("Address copied successfully")
                                                                }
                                                                text={item.copy}
                                                            >
                                                                <p
                                                                    className={styles.text}
                                                                    style={{cursor: "pointer"}}
                                                                >
                                                                    {item.text}
                                                                </p>
                                                            </CopyToClipboard>
                                                        ) : (
                                                            <p
                                                                className={styles.text}
                                                                style={{
                                                                    color: `${
                                                                        item.text === "Rejected"
                                                                            ? "red"
                                                                            : item.text === "Pending"
                                                                                ? "orange"
                                                                                : item.text === "Active"
                                                                                    ? "green"
                                                                                    : item.text === "Claimed"
                                                                                        ? "brown"
                                                                                        : item.text === "Refunded"
                                                                                            ? "black"
                                                                                            : ""
                                                                    }`
                                                                }}
                                                            >
                                                                {item.text}
                                                            </p>
                                                        )}
                                                    </div>
                                                )}

                                                {item?.btn && (
                                                    <div>
                                                        <Button
                                                            disabled={item?.disabled || loading}
                                                            click={() => item?.action(data)}
                                                        >
                                                            {item?.text}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                </div>
                {borrow && (
                    <div className={styles.refundButtonsContainer}>
                      <span className="mx-5">
                        <Button
                            btnStyles={{marginRight: "1.2rem"}}
                            background="#E7E7E7"
                            color="#323232"
                            disabled={disabled}
                            click={() =>
                                handleSellItem ? handleSellItem(data[0]?.data) : null
                            }
                        >
                          {"Sell item"}
                        </Button>
                      </span>
                        <Button
                            click={() =>
                                handleRefundItem ? handleRefundItem(data[0]?.data) : null
                            }
                        >
                            {"Refund"}
                        </Button>
                    </div>
                )}
                {claimFunds &&
                    ["rejected", "pending"]?.includes(data[0]?.data?.status) && (
                        <div className={styles.refundButtonsContainer}>
                            <Button
                                click={() =>
                                    claimFunds!({
                                        item: data[0]?.data,
                                        action:
                                            data[0]?.data?.status === "rejected" ? "claim" : "cancel"
                                    })
                                }
                                disabled={loading?.status}
                            >
                                {loading?.status && loading?.id === data[0]?.data?._id
                                    ? "loading"
                                    : data[0]?.data?.status === "rejected"
                                        ? "Redeem"
                                        : "Cancel Offer"}
                            </Button>
                        </div>
                    )}
            </div>
        );
    };
export default LendingDashboardItem;
