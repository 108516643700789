import React, {SetStateAction, useEffect, useState} from "react";
import Button from "../../Button/Button";
import { useDataContext } from "../../../context/context";
import styles from "./Estimate.module.css";
import { useNavigate } from "react-router-dom";
import EstimateModal from "./EstimateModal/EstimateModal";
import { handleRequest } from "../../../utilities/common.utility";
import { toast } from "react-toastify";

const Estimate = () => {
  const [estimationCompleted, setEstimationCompleted] = useState(false);
  const { estimateAmount, setEstimateAmount, connect } = useDataContext();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (connect) {
      getEstimate();
    }
  }, [connect]);

  const getEstimate = async () => {
    try {
      const res = await handleRequest(
        "get",
        `estimate`,
        {} ,
        { address: connect, status: "pending" }
      );

      if (res?.data?.success) {
        if (!(res.data?.data?.length > 0)) {
          setEstimateAmount(null);
        }
        const { amount } = res?.data?.data[0];
        if (amount) {
          setEstimateAmount(amount);
        }
      }
    } catch (error) {}
  };

  const updateEstimate = async (status:string) => {
    try {
      setLoader(true);
      const res = await handleRequest(
        "put",
        `estimate/${connect?.toLowerCase()}`,
        { status }
      );
      if (res.data.success) {
        if (status === "assetBack") {
          navigate("/connect");
        }
        setEstimationCompleted(true);
        setLoader(false);
      } else {
        toast.error(res.data.message);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <section className={`${styles.wrapper} wrapper`}>
      {!estimationCompleted && (
        <>
          {estimateAmount && (
            <>
              <h1 className={`title ${styles.title}`}>Hello there!</h1>
              <p className={`text ${styles.text}`}>
                {" "}
                “Congratulations! You have received your estimation.”
              </p>
              <p className={`text ${styles.text}`}>
                Owna is willing to lend you up to:{" "}
                <span className="title">{estimateAmount} Dollars!</span>
              </p>
              <div className={styles.buttonContainer}>
                <div onClick={() => updateEstimate("assetBack")}>
                  <Button color="#323232" background="#E7E7E7">
                    I want it Back
                  </Button>
                </div>

                <div onClick={() => updateEstimate("accepted")}>
                  <Button>{loader ? "loading" : " Let’s Go!"}</Button>{" "}
                </div>
              </div>
            </>
          ) 
          }
        </>
      )}
      {estimationCompleted && (
        <EstimateModal setPopup={setEstimationCompleted} getEstimate={getEstimate}/>
      )}
    </section>
  );
};

export default Estimate;
