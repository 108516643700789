import React from "react";
import { ENV } from "../../../../config/config";
import styles from "../Card/styles.module.css";
type CardProps = {
    cardTitle: string;
    price: string;
    image: string;
    connect: string | null;
    scrollTotop: () => void;
    data: any;
    ownerAddress: string;
    id: string;
    onClick: () => void;
    onMakeOffer: () => void;
};

const Card: React.FC<CardProps> = (
    {
        cardTitle,
        price,
        image,
        connect,
        scrollTotop,
        data,
        ownerAddress,
        onClick,
        onMakeOffer,
    }) => {

    const isLocked = data.status == "borrowed";
    let error = false;
    if (connect) {
        error = !!data.offers.find((offer: any) => offer.fromAddress == ENV.getUserKeys("walletAddress")?.walletAddress);
    }

    return (
        <>
            <div className="w-full bg-white group shadow-md rounded-xl overflow-hidden">
                <picture className="overflow-hidden flex justify-center">
                    <img
                        src={image}
                        alt="card_avatar"
                        className="cursor-pointer group-hover:scale-125 ease-in duration-150 h-[215px] w-full object-fill"
                        onClick={() => {
                            if (error || ownerAddress === connect?.toUpperCase() || isLocked) {
                                return;
                            }
                            onClick();
                        }}
                    />
                </picture>

                <div className="flex flex-col items-center h-24 overflow-hidden">
                    <div className="font-semibold">{cardTitle}</div>
                    <div className="font-semibold inline-block group-hover:!hidden text-[14px] font-[400!important] mt-2" >
                        Max Borrowing Capacity:
                    </div>
                    <div className="font-semibold" >
                        {price}
                        {" USDC"}  <span className="text-[14px] inline-block group-hover:!hidden ease-in duration-150 font-[400!important] ">(+25% debt)</span>
                    </div>

                    <div
                        style={{ background: isLocked ? 'linear-gradient(90deg, #dbbaca 0%, #dbbaca 100%)' : 'linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%)' }}
                        className={`relative top-12 group-hover:top-0 h-10 flex justify-center mt-2 w-full ${isLocked ? 'bg-[#a2bdd8]' : 'bg-[#2E8DEE]'} font-bold text-white duration-150 `}
                    >
                        <button
                            className={(error || ownerAddress === connect?.toUpperCase() || isLocked) ? styles.disabledBtn : undefined}
                            disabled={error || ownerAddress === connect?.toUpperCase() || isLocked}
                            onClick={() => {
                                scrollTotop();
                                onMakeOffer();
                            }}
                        >
                            {isLocked ? "Active Loan" : "Make Offer"}
                        </button>
                    </div>

                    {error && (
                        <p className={`${styles.metamaskCls}`}>
                            You have already placed an offer
                        </p>
                    )}
                </div>
            </div>
            {/* <div className={styles.cards}>
                <div className={styles.cardImage}>
                    <img src={image} alt="card_avatar" className="cursor-pointer hover:scale-125 ease-in duration-150" onClick={() => {
                        if (error || ownerAddress === connect?.toUpperCase() || isLocked) {
                            return;
                        }
                        onClick();
                    }} />
                </div>
                <div>
                    <div className={styles.cardTitle}>{cardTitle}</div>
                    <div className={styles.cardPrice}>
                        {price}
                        {" USDC"}
                    </div>
                    <div className={styles.cardBtn}>
                        <button
                            className={(error || ownerAddress === connect?.toUpperCase() || isLocked) ? styles.disabledBtn : undefined}
                            disabled={error || ownerAddress === connect?.toUpperCase() || isLocked}
                            onClick={() => {
                                scrollTotop();
                                onMakeOffer();
                            }}
                        >
                            {isLocked ? "Lock" : "Make Offer"}
                        </button>
                    </div>

                    {error && (
                        <p className={`${styles.metamaskCls}`}>
                            You have already placed an offer
                        </p>
                    )}
                </div>
            </div> */}
        </>
    );
};

export default Card;
