import React from "react";
import {Box} from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    className?: React.ComponentProps<any>
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nft-tabpanel-${index}`}
            aria-labelledby={`nft-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 2, overflow: 'auto'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default TabPanel;