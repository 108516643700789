import React from "react";
import styles from "./Heading.module.css";

interface IProps {
    title: string;
    text?: string;
    padding?: boolean;
    noPadding?: boolean;
    connect?: boolean;
    message?: string;
}

const Heading = ({ title, text, padding, noPadding, connect, message }:IProps) => {
    return (
    <div
      className={styles.heading}
      style={{
        paddingLeft: padding ? "15px": '',
        padding: noPadding? "0 0": '',
      }}
    >
      <h1 className={styles.title}>
        {" "}
        {title}
      </h1>
      {text && <p className={styles.text}>{text}</p>}
      {!connect && <p className={styles.metamaskCls}>{message && message}</p>}
    </div>
  );
};

export default Heading;
