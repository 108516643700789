import * as React from "react";
import cx from "classnames";

export const FAQ = () => {
  const [selected, setSelected] = React.useState(-1);

  const toggle = (index:number) => {
    if (index === selected) {
      setSelected(-1);
    } else {
      setSelected(index);
    }
  };
  const questions = [
    {
      question: "What is pawnbroking?",
      answer: [
        "Pawnbroking is a loan secured by the value of an object (jewelry, watch, paintings, car, sneakers' ). By bringing your property to an Owna location and providing all the required documents, you will be able to borrow money and tokens up to 70% of your property's value. If you don't pay back, the item will be sold and you will be reimbursed according to the resale price.",
      ],
    },
    {
      question: "What type of assets can be pledged?",
      answer: [
        "Owna accepts assets of 800 euros mininmum and part of these following caterogies:",
        "Jewelry",
        "Watches",
        "Stones",
        "Gold",
        "Paintings & Photos",
        "Collection Cars ",
      ],
    },
    {
      question: "First Step?",
      answer: [
        "Download a wallet such as Metamask or Rainbow to connect to Owna application.",
        "With our decentralized application, you can easily schedule appointments or lend stablecoins to other users.",
      ],
    },
    {
      question: "How to get an estimate?",
      answer: [
        "To get an estimate of your property, simply make an appointment through our application. Once  brought in, an accredited expert will verify the authenticity of the property and provide an estimate. It generally takes 72 hours, and up to a week.",
      "Beware that: "
      ],
      list: [
        "Any asset can be recovered by making an appointment on Owna.io ",
        "Any fake asset will be confiscated by the brand verifying the asset (Eg: Rolex)"
      ],
    },
    {
      question: "Where are the assets secured?",
      answer: [
        "Owna safeguards and protects your property at the bank with the assistance of a reliable banking provider and security service."
      ]
    },
    {
      question: "How do I get a loan?",
      answer: [
        "Once your property is appraised, Owna will request your approval directly within the app. Your property will be securely vaulted, and you will receive the NFT representing your asset in your Wallet. The NFT, held in escrow on the Owna marketplace, will enable you to accept offers from peer-to-peer lenders and borrow stablecoins."
      ],
    },
       {
      question: "Two types of lending offers:",
      answer: [
        "In your dashboard, you can discover that the lenders have presented you with two types of loan offers for your asset:",
        <span><b>Fixed Offer:</b> A loan that requires to be reimbursed within limited number of days set by the lender</span>,
        <span><b>Flexible Offer:</b> A loan that is unlimited in time but that requires a debt below a certain APR set by the  lender. (Max: 25% debt allowance)</span>
      ]
    },
    {
      question: "What loan rates can I expect?",
      answer: [
        "Loan rates are at the discretion of the borrowers and lenders on our platform.",
        "You can expect a 2% listing fee per property. This fee covers the valuation, logistics, escrow and insurance of your property.",
        "Then you can expect 1% interest rates per month. All fees and debt are payable at the end of the loan or prior the deadline. ",
      ],
    },
    
    {
      question: "How is my debt calculated?",
      answer: [
        "You accumulate your daily rate (1/365th of your annual rate) defined by the volume of stablecoin borrowed and accepted loan rate.",

        <span><b>Eg:</b> if you pay back your loan after 46 days, you will only have to reimburse 46 days of your yearly rate + entry fee. </span>,

        "Thanks to Owna smart contracts you only pay as you use!"
      ],
    },
   
    {
      question: "How do I reimburse my loan?",
      answer: [
        "You can repay your loan at any time by logging into our application and going to the borrowing dashboard tab.",
        "Note: You can pay back your loan all at once or in parts.",
        'Once paid back, your NFT will be burnt and you will have the option to retrieve your property or get your asset revalued for a new loan.',
      ],
    },
    {
      question: "What happens if I can't pay back?",
      answer: [
        "Your property will be sold at auctions (Private and/or Blockchain) to repay your loan. Once sold, the capital gains from the sale will be refunded to you after a 10% commission applied by Owna.",
        <b>Note:</b>,
      ],
      list: [
        "The loan will still be ongoing after a RESELL or DEFAULT, but capped to 25% maximum debt",
        "The lender will be reimbursed once the asset is sold and the reimbursement is capped to 25% interests. Some incentives, at the discretion of Owna, will  be distributed depending on the success of the sale of the asset."
      ],
    },
    {
      question: "How long does a pledge last and how does a renewal work?",
      answer: [
        "Fixed Offer: Pay off within 365 days.",
        "Flexible Offer: Keep your debt load below the accepted yearly rate of your contract. Note that the maximum yearly acceptable debt is 25%",
      ],
    },
    {
      question: "What money and stablecoins can I get?",
      answer: ["USDC and soon many more!"],
    },
  ];

  return (
    <div className="max-w-screen-md mx-auto">
      {questions.map((question, index) => {
        const isCurrent = selected !== index;
        return (
          <div className="py-5 border-b border-pink-100" key={index}>
            <div
              className="text-lg lg:text-2xl flex justify-between items-center cursor-pointer"
              onClick={() => toggle(index)}
            >
              {question.question}
              <span className="text-mainColor-500 text-3xl lg:text-4xl font-light ml-5 w-10 h-10 flex justify-center items-center">
                {isCurrent ? "+" : "-"}
              </span>
            </div>
            <div
              className={cx("text-body leading-relaxed mt-4 lg:text-lg", {
                hidden: isCurrent,
              })}
            >
              {question.answer.map((el, i) => (
                <p className="mt-2" key={i}>
                  {el}
                </p>
              ))}{" "}
              {question.list && (
                <ul className="mt-5 list-disc ml-4">
                  {question.list.map((el, i) => (
                    <li key={i}>{el}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
