import React from "react";
import {useState, useEffect, useCallback} from "react";
import styles from "./Dashboard.module.css";
import {Box, Tab, Tabs} from "@mui/material";
import Heading from "../../Heading/Heading";
import {useDataContext} from "../../../context/context";
import {useNavigate} from "react-router-dom";
import BorrowedItemsDashboard from "./BorrowedItemsDashboard/BorrowedItemsDashboard";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import ClaimOffers from "./ClaimOffers/ClaimOffers";
import {ENV} from "../../../config/config";
import {getNetwork, getChainId} from "../../../utilities/web3.utility";
import P2poffer from "../P2poffer/P2poffer";
import Estimate from "../Estimate/Estimate";
import TabPanel from "../../Tab/TabPanel";

const Dashboard = () => {
    const {connect} = useDataContext();
    const [admin, setAdmin] = useState(false);
    const [isValidNetwork, setIsValidNetwork] = useState(false);
    const [p2poffers, setP2Poffers] = useState(null);
    const [hidePlaceholder, setHidePlaceHolder] = useState(false);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<number>(0);

    const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        validateNetwork();
        if (connect && isValidNetwork) {
            isAdmin();
        } else {
            setAdmin(false);
        }
        window?.ethereum?.on("chainChanged", () => {
            validateNetwork();
        });
    }, [connect, isValidNetwork]);

    const validateNetwork = async () => {
        const chainId = await getChainId();
        if (ENV.chainId !== chainId || !connect) {
            setIsValidNetwork(false);
            return false;
        } else {
            setIsValidNetwork(true);

            return true;
        }
    };

    const isAdmin = (address?: string) => {
        let connectedAddress = address ? address?.toUpperCase() : connect?.toUpperCase();
        if (
            ENV.admin!.split(", ")
                .map((x) => x.toUpperCase())
                .includes(connectedAddress)
        ) {
            setAdmin(true);
            return true;
        } else {
            setAdmin(false);
            return false;
        }
    };

    const bringAsset = () => {
        // Disable KYC check
        // if (!validateIfProfileIsUpdated()) {
        //     toast.info("Please complete profile first");
        //     return navigate("/myprofile");
        // }
        navigate("/appointment");
    };

    const onGetAssetBack = () => {

    }

    const wrapperSetP2Poffers = useCallback(
        (offers: any) => {
            setP2Poffers(offers);
        },
        [setP2Poffers]
    );

    return (
        <>
            <section className={` ${styles.dashboard}`}>
                <div className="container">
                    <div className={`${styles.dashboard2}`}>
                        <Heading
                            title="Dashboard"
                            connect={isValidNetwork ?? connect}
                            message={`please connect your metamask to ${getNetwork(ENV.chainId).name} network`}
                            noPadding
                        />
                    </div>
                    <div>
                        <Estimate/>
                    </div>
                    <div className="mt-4">
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs
                                onChange={handleTabChange}
                                aria-label="dashboard tabs"
                                variant="fullWidth"
                                textColor="secondary"
                                indicatorColor="secondary"
                                value={activeTab}
                            >
                                <Tab label="Borrowing" id="borrowing-tab-0" aria-controls="dashboard-tabpanel-0" sx={{fontSize: 16, fontWeight: 'bold'}}/>
                                <Tab label="Lending" id="lending-tab-1" aria-controls="dashboard-tabpanel-1" sx={{fontSize: 16, fontWeight: 'bold'}}/>
                            </Tabs>
                        </Box>
                        <TabPanel value={activeTab} index={0}>
                            <BorrowedItemsDashboard
                                admin={admin}
                                isValidNetwork={isValidNetwork}
                                bringAsset={bringAsset}
                                onGetAssetBack={onGetAssetBack}
                                p2poffers={p2poffers}
                                setHidePlaceHolder={setHidePlaceHolder}
                                hidePlaceholder={hidePlaceholder}
                            />
                            {!admin && (
                                <div className={`${styles.dashboard2} ${styles.testing}`}>
                                    <P2poffer
                                        setP2Poffers={wrapperSetP2Poffers}
                                        setHidePlaceHolder={setHidePlaceHolder}
                                        hidePlaceholder={hidePlaceholder}
                                    />
                                </div>
                            )}
                            {admin && (
                                <AdminDashboard
                                    isValidNetwork={isValidNetwork}
                                    hidePlaceholderr={hidePlaceholder}
                                />
                            )}
                        </TabPanel>
                        <TabPanel value={activeTab} index={1}>
                            <div className={`${styles.dashboard2} ${styles.testing}`}>
                                <ClaimOffers
                                    isValidNetwork={isValidNetwork}
                                    hidePlaceholder={hidePlaceholder}
                                />
                            </div>
                        </TabPanel>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Dashboard;
