import cx from "classnames";
import React from "react";


export const Container = ({ children, bgColor, withBorder = true, className }:any) => {
    return (
        <section
            className={cx({
                "bg-white relative pb-4 md:pb-6 md:px-6": withBorder,
            })}
        >
            <div className={cx(bgColor, "relative", className)}>
                <div className="container mx-auto">{children}</div>
            </div>
        </section>
    );
};

Container.Lame = ({ children, className }:any) => <div className={cx("py-10 lg:py-28 px-4 lg:px-12", className)}>{children}</div>;
