import React, {useState, useEffect} from "react";
import {handleRequest} from "../../../../utilities/common.utility";
import {
    fixedBorrow,
    flexibleBorrow,
    readDynamicInterest,
    payableAmountForBorrower,
} from "../../../../utilities/Contracts/OwnaLendingAndBorrowing/ownaLendingAndBorrowingContractFunctions";
import {getWeb3} from "../../../../utilities/web3.utility";
import styles from "../Dashboard.module.css";
import {toast} from "react-toastify";
import LendingDashboardItem from "../LendingDahboardComponent";
import {useDataContext} from "../../../../context/context";
import RefundPopup from "../RefundPopup/RefundPopup";
import moment from "moment";


interface AdminDashboardProps {
    isValidNetwork: boolean;
    hidePlaceholderr?: boolean;
}

interface EstimateItem {
    info: {
        title: string;
        text: string | number;
        copy?: string;
    }[];
}


interface BorrowedItem {
    info: {
        data: {
            type: string;
            mintId: {
                tokenId: number;
                ownerAddress: string;
                fileUrl: string;
            };
            offerId: {
                type: string;
                fixedOfferId?: number;
                flexibleOfferId?: number;
            };
            createdAt: string;
        };
    }[];
}

const AdminDashboard = ({isValidNetwork, hidePlaceholderr}: AdminDashboardProps) => {

    const [estimates, setEstimates] = useState<EstimateItem[] | null>(null);
    const [expiredItems, setExpiredItems] = useState<BorrowedItem[] | null>(null);
    const [refundpopup, setRefundPopup] = useState(false);
    const [refundData, setRefundData] = useState<any>(null);
    const {connect, scrollToTop} = useDataContext();

    useEffect(() => {
        if (connect && isValidNetwork) {
            getEstimate();
            getExpiredBorrowedItems();
        } else {
            setEstimates(null);
            setExpiredItems(null);
        }
    }, [connect, isValidNetwork]);

    const getEstimate = async () => {
        try {
            const res = await handleRequest(
                "get",
                `estimate`,
                {},
                {status: "accepted"}
            );

            if (res?.data?.success) {
                if (res.data?.data?.length > 0) {
                    const acceptedEst = res.data.data.map((item: any) => {
                        return {
                            info: [
                                // { info: item },
                                {
                                    title: "Owner address",
                                    text:
                                        item.ownerAddress.slice(0, 6) +
                                        "..." +
                                        item.ownerAddress.slice(-6),
                                    copy: item.ownerAddress
                                },

                                {title: "Amount", text: item.amount},

                                {
                                    title: "Reference",
                                    text: item.reference,
                                },
                                {
                                    title: "Status",
                                    text: item.status,
                                },
                                {
                                    title: "date",
                                    text: moment(item.createdAt).format("DD MMM hh:mm"),
                                },
                            ],
                        };
                    });
                    setEstimates(acceptedEst);
                }
            } else {
                toast.error(res.data.message);
            }
        } catch (error: any) {
            toast.error(error.messsage);
        }
    };

    const getExpiredBorrowedItems = async () => {
        try {
            const res = await handleRequest(
                "get",
                `borrow/getBorrowedItems`,
                {},
                {status: "sellIt"}
            );
            const {data} = res?.data;
            if (data && data?.length > 0) {
                const results = await Promise.all(
                    data.map(async (item: any) => {
                        const web3 = await getWeb3();
                        let contractSyncBorrowInfo = undefined;
                        let contractInterest = undefined;
                        let amountOfBorrower = undefined;
                        if (item.type === "fixed") {
                            contractSyncBorrowInfo = await fixedBorrow(item?.mintId?.tokenId);
                        } else {
                            contractSyncBorrowInfo = await flexibleBorrow(
                                item?.mintId?.tokenId
                            );
                        }
                        contractInterest = await readDynamicInterest(
                            item?.mintId?.tokenId,
                            item.offerId.type === "fixed"
                                ? item.offerId.fixedOfferId
                                : item.offerId.flexibleOfferId
                        );
                        amountOfBorrower = await payableAmountForBorrower(
                            item?.mintId?.tokenId,
                            item.offerId.type === "fixed"
                                ? item.offerId.fixedOfferId
                                : item.offerId.flexibleOfferId
                        );

                        contractInterest = Number(web3.utils.fromWei(contractInterest || "0", 'mwei'));
                        let contractOutStandingLoan = Number(web3.utils.fromWei(
                            contractSyncBorrowInfo.loanAmount || "0", 'mwei'
                        ));

                        contractSyncBorrowInfo = Number(web3.utils.fromWei(
                            amountOfBorrower || "0",
                            'mwei'
                        ));

                        contractOutStandingLoan = contractOutStandingLoan + contractInterest;

                        return {
                            info: [
                                {
                                    data: {
                                        ...item,
                                        contractSyncBorrowInfo,
                                        contractInterest,
                                        contractOutStandingLoan,
                                        amountOfBorrower,
                                    },
                                },
                                {
                                    title: "Lender",
                                    text:
                                        item?.mintId?.ownerAddress.slice(0, 6) +
                                        "..." +
                                        item?.mintId?.ownerAddress.slice(-6),
                                    img: item.mintId.fileUrl,
                                    copy: item?.mintId?.ownerAddress,
                                },
                                {
                                    title: "Received",
                                    text: moment(item?.createdAt).format("DD MMM hh:mm"),
                                },
                                {
                                    title: "Remaining Debt",
                                    text: `${contractOutStandingLoan} USDC `,
                                },
                                {
                                    title: "Status",
                                    text: "sell-it",
                                },
                                {
                                    btn: true,
                                    text: "Refund",
                                    action: (data: any) => {
                                        scrollToTop();
                                        setRefundData(data[0].data);
                                        setRefundPopup(true);
                                    },
                                },
                            ],
                        };
                    })
                );
                setExpiredItems(results);
            } else {
                setExpiredItems(null);
            }
        } catch (err) {
        }
    };

    return (
        <>
            {" "}
            <div className={`${styles.dashboard2} ${styles.testing}`}>
                <h2 className={`title ${styles.title}`}>Accepted estimations</h2>
                {estimates &&
                    estimates?.length > 0 &&
                    estimates.map((item: any, index) => <LendingDashboardItem key={"estimates" + index} data={item.info}/>)}
            </div>
            <div className={`${styles.dashboard2} ${styles.testing} `}>
                <h2 className={`title ${styles.title} mt-3`}>Items to Sale</h2>
                {expiredItems &&
                    expiredItems?.length > 0 &&
                    expiredItems.map((item: any, index) => (
                        <LendingDashboardItem
                            key={"expiredItems" + index}
                            data={item.info}
                            handleRefundItem={(data: any) => {
                                scrollToTop();
                                setRefundData(data);
                                setRefundPopup(true);
                            }}
                        />
                    ))}
            </div>
            {refundpopup && refundData && (
                <RefundPopup
                    setPopup={setRefundPopup}
                    getBorrowedItems={getExpiredBorrowedItems}
                    admin={true}
                    connect={connect}
                    borrowContractDetails={{
                        totalRepayDebt: Number(refundData?.contractOutStandingLoan),
                        amountOfBorrower: refundData.amountOfBorrower,
                    }}
                    min={0}
                    owna
                    offerDetails={{
                        ...refundData.offerId,
                        borrowId: refundData._id,
                        loanMaxValue: 0 || refundData.outStandingLoan,
                        nftId: refundData.mintId.tokenId,
                        interest: refundData.interest,
                        contractInterest: refundData.contractInterest,
                        dailyInterest: refundData.dailyInterest,
                        amount: refundData.amount,
                        maximumacceptabledebt: refundData.offerId.maximumacceptabledebt,
                    }}
                />
            )}
        </>
    );
};

export default AdminDashboard;
