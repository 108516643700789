import React from 'react';
import BidTable from './BidTable';

type BidsData = {
    _id: number;
    loanMaxValue: string;
    status: string;
    type: string;
    apr: string;
    loanMinValue: string;
    toAddress: string;
    period: number;
};

const BidsList: React.FC<{ imgUrl: string, offers: BidsData[] }> = ({imgUrl, offers}) => {
    // const bidsData: BidsData[] = [
    //     {
    //         id: 1,
    //         image: 'image-url-1',
    //         maxDebtAllowance: '1000 USDC',
    //         status: 'Active',
    //         type: 'flexible',
    //         apr: '10%',
    //         volume: '500 USDC',
    //         lender: 'Lender 1',
    //         days: 7,
    //     },
    //     {
    //         id: 2,
    //         image: 'image-url-2',
    //         maxDebtAllowance: '2000 USDC',
    //         status: 'Redeemed',
    //         type: 'fixed',
    //         apr: '8%',
    //         volume: '1000 USDC',
    //         lender: 'Lender 2',
    //         days: 7,
    //     },
    //     // Add more bid data objects as needed
    // ];

    return (
        <div>
            {offers.map((bid) => (
                <BidTable
                    key={bid._id}
                    image={imgUrl}
                    maxDebtAllowance={bid.loanMaxValue}
                    status={bid.status}
                    type={bid.type}
                    apr={bid.apr}
                    volume={bid.loanMinValue}
                    lender={bid.toAddress}
                    days={bid.period}
                />
            ))}
        </div>
    );
};

export default BidsList;
