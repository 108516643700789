import MyProfile from "../components/pages/MyProfile/MyProfile";
import AppointeMentInfo from "../components/pages/AppointmentInfo/AppointeMentInfo";
import LetsConnect from "../components/pages/LetsConnect/LetsConnect";
import WhatAreYouWillingTodo from "../components/pages/WhatAreYouWillingTodo/WhatAreYouWillingTodo";
import Estimate from "../components/pages/Estimate/Estimate";
import MarketPlace from "../components/pages/MarketPlace/MarketPlace";
import Dashboard from "../components/pages/Dashboard/Dashboard";
import Stake from "../components/pages/Stake/Stake";
import MintNft from "../components/BackOffice/MintNft/MintNft";

import Calim from "../components/pages/Calim/Calim";
import BackOfficeEstimate from "../components/BackOffice/BackOfficeEstimate/BackOfficeEstimate";
import SendOffer from "../components/BackOffice/SendOffer/SendOffer";
import FrontPage from "../components/pages/FrontPage/FrontPage";
let routes = [
  {
    path: "/",
    access: true,
    title: "Home",
    component: FrontPage,
    role: "common",
  },
  {
    path: "/myProfile",
    access: true,
    title: "Home",
    component: MyProfile,
    role: "common",
  },
  {
    path: "/appointment",
    access: true,
    title: "Home",
    component: AppointeMentInfo,
    role: "user",
  },
  {
    path: "/connect",
    access: true,
    title: "test",
    component: LetsConnect,
    role: "common",
  },
  {
    path: "/estimate",
    access: true,
    title: "test",
    component: Estimate,
    role: "common",
  },
  {
    path: "/whatareyouwillingtodo",
    access: true,
    title: "test",
    component: WhatAreYouWillingTodo,
    role: "common",
  },
  {
    path: "/marketplace",
    access: true,
    title: "test",
    component: MarketPlace,
    role: "common",
  },
  {
    path: "/dashboard",
    access: true,
    title: "test",
    component: Dashboard,
    role: "common",
  },
  {
    path: "/stake",
    access: true,
    title: "test",
    component: Stake,
    role: "common",
  },
  {
    path: "/mint",
    access: false,
    title: "test",
    component: MintNft,
    role: "admin",
  },
  {
    path: "/common",
    access: true,
    title: "test",
    component: Calim,
    role: "common",
  },
  {
    path: "/sendestimate",
    access: true,
    title: "test",
    component: BackOfficeEstimate,
    role: "admin",
  },
  {
    path: "/sendoffer",
    access: true,
    title: "test",
    component: SendOffer,
    role: "common",
  },
];

export default routes;
