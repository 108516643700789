import React, {useEffect, useState} from "react";
import BidsList from "./BidsList";
import AboutTab from "./AboutTab";
import IdealDeal from "./IdealDeal";
import Button from "../../../../Button/Button";
import {Box, Tab, Tabs} from "@mui/material";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./nft-popup.module.css";
import TabPanel from "../../../../Tab/TabPanel";
import {handleRequest} from "../../../../../utilities/common.utility";
import {ENV} from "../../../../../config/config";
import {toast} from "react-toastify";

type NFTCardProps = {
    el: {
        title: string;
        fileUrl: string;
        facingUrl: string;
        backUrl: string;
        leftFUrl: string;
        rightFUrl: string;
        maxBorowCapacity: number;
        _id: string;
        ownerAddress?: string;
        properties: Array<{name: string, type: string}>;
        levels: Array<{name: string, type: string}>;
        stats: Array<{name: string, type: string}>;
        description: string;
    };
    connect: any;
    scrollToTop: any;
};
type NFTPopupProps = {
    onClose: () => void;
    onMakeOffer: () => void;
};

const NFTPopup: React.FC<NFTPopupProps & NFTCardProps> = (
    {
        el,
        connect,
        scrollToTop,
        onClose,
        onMakeOffer
    }) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        getPlacedOffers();
    }, []);

    const getPlacedOffers = async () => {
        try {
            const res = await handleRequest(
                "get",
                `offer/gerOffersByNFT?mintId=${el?._id}`
            );
            const { offers } = res.data;
            if (offers && offers?.length > 0) {
                setOffers(offers);
            }
            if (res.data.success) {
            } else {
                // @ts-ignore
                toast.err(res.data.message);
            }
        } catch (err) {
            // @ts-ignore
            toast.err(err);
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
        setActiveTab(tab);
    };

    const handleBackgroundClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    // TODO replace the attributes of NFT from property "el"
    const attributes = [
        {
            name: "Color",
            value: "Gold",
        },
        {
            name: "Year",
            value: "2023",
        },
        {
            name: "Movement",
            value: "Automatic",
        },
        {
            name: "Material",
            value: "Steel",
        },
        {
            name: "Power",
            value: "72",
        }];

    const renderScreenShotList = (screenshot: string, i: number) => {
        return (
            <img
                key={i + "nft-image"}
                className={styles.nftPopupImage}
                src={screenshot}
                alt=''
            />
        );
    };

    const renderThumbList = (screenshot: string, i: number) => {
        return (
            <img
                key={i + "nft-thumb"}
                src={screenshot}
                alt=''
            />
        );
    };

    let nftImages = [el.fileUrl, el.facingUrl, el.backUrl, el.leftFUrl, el.rightFUrl].filter((img) => !!img);
    const renderNFTImages = nftImages.map(renderScreenShotList);

    return (
        <>
            <section className={styles.popupWrapper}>
                <div className={`w-full max-w-6xl ${styles.popup}`}>
                    <div className="md:grid grid-cols-5 gap-4 p-6">
                        <div className="col-span-2">
                            <div className={styles.nftPopupImageContainer}>
                                <Carousel
                                    swipeable={true}
                                    showArrows={true}
                                    showThumbs={true}
                                    renderThumbs={() => nftImages.map(renderThumbList)}
                                >
                                    {renderNFTImages}
                                </Carousel>
                            </div>
                        </div>

                        <div className="col-span-3">
                            <h2 className="text-2xl font-bold mb-4">{el.title}</h2>
                            <p className="mb-4">Preferences: preferences</p>
                            <Button className="button" width="100%" click={onMakeOffer}>
                                Make Offer
                            </Button>

                            <div className="mt-6">
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <Tabs
                                        onChange={handleTabChange}
                                        aria-label="nft tabs"
                                        variant="fullWidth"
                                        textColor="secondary"
                                        indicatorColor="secondary"
                                        value={activeTab}
                                    >
                                        <Tab label="About" id="nft-tab-0" aria-controls="nft-tabpanel-0"/>
                                        <Tab label="Bids" id="nft-tab-1" aria-controls="nft-tabpanel-1"/>
                                        <Tab label="Ideal Deal" id="nft-tab-2" aria-controls="nft-tabpanel-2"/>
                                    </Tabs>
                                </Box>
                                <TabPanel value={activeTab} index={0}>
                                    <AboutTab
                                        description={el.description}
                                        storage="100 GB"
                                        estimate="5 hours"
                                        properties={el.properties}
                                        levels={el.levels}
                                        stats={el.stats}
                                    />
                                </TabPanel>
                                <TabPanel value={activeTab} index={1}>
                                    <BidsList imgUrl={el.fileUrl} offers={offers}/>
                                </TabPanel>
                                <TabPanel value={activeTab} index={2}>
                                    <IdealDeal
                                        volume="$11000"
                                        currency="UDSC"
                                        apr="5"
                                        type="Flexible"
                                        network="Polygon, BNB Chain"
                                    />
                                </TabPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div
                className={styles.modal}
                onClick={handleBackgroundClick}
            ></div>
        </>
    );
};

export default NFTPopup;
