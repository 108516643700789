import React, {useState} from "react";
import Button from "../../../Button/Button";
import styles from "./EstimateModal.module.css";
import {useNavigate} from "react-router-dom";
import { IconButton, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import {OfferType} from "../../../../utilities/types";
import {getOfferTypeLabel} from "../../../../utilities/onboard";
import { MdHelp } from "react-icons/md";

type Props = {
    setPopup: React.Dispatch<React.SetStateAction<boolean>>;
    getEstimate: () => void;
};

const EstimateModal = ({setPopup, getEstimate}: Props) => {
    const navigate = useNavigate();
    const [type, setType] = useState<string>(OfferType.Fixed);
    const [days, setDays] = useState<number>();
    const [amount, setAmount] = useState<number>();
    const [apy, setAPY] = useState<number>();
    const [maxAcceptableDebt, setMaxAcceptableDebt] = useState<number>();

    return (
        <>
            <section className={styles.container}>
                <div className={`${styles.popup}`}>
                    <h1 className={`title ${styles.title}`}>“Congratulations!”</h1>

                    <div className={styles.textContainer}>
                        <p className={`text ${styles.text}`}>Now, what’s next?</p>
                        <p className={`text ${styles.text}`}>
                            Check your email to sign any remaining documents.
                        </p>
                        <p className={`text ${styles.text}`}>
                            {" "}
                            This will allow you to receive P2P loan offers
                            <br/> and borrow stablecoins.
                        </p>
                    </div>

                    <label className={styles.formLabel}>Desired Terms *Optional:<Tooltip title="You can display what whould be your ideal Loan terms for both parties and ensure the lenders makes the closest offer to you" placement="top"><IconButton size="small"><MdHelp /></IconButton></Tooltip></label>
                    <Select
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        id="demo-simple-select"
                        value={type}
                        className={styles.formItem}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <MenuItem value={OfferType.Fixed}>{getOfferTypeLabel(OfferType.Fixed)}</MenuItem>
                        <MenuItem value={OfferType.Flexible}>{getOfferTypeLabel(OfferType.Flexible)}</MenuItem>
                    </Select>
                    {
                        type == OfferType.Fixed &&
                        <TextField
                            id="days"
                            inputProps={{ 'aria-label': 'Without label' }}
                            placeholder="Days"
                            variant="outlined"
                            value={days}
                            className={styles.formItem}
                            onChange={(e) => setDays(Number(e.target.value))}
                        />
                    }
                    <TextField
                        id="amount"
                        inputProps={{ 'aria-label': 'Without label' }}
                        placeholder="Amount"
                        variant="outlined"
                        value={amount}
                        className={styles.formItem}
                        onChange={(e) => setAmount(Number(e.target.value))}
                    />
                    <TextField
                        id="apy"
                        inputProps={{ 'aria-label': 'Without label' }}
                        placeholder="APY"
                        variant="outlined"
                        value={apy}
                        className={styles.formItem}
                        onChange={(e) => setAPY(Number(e.target.value))}
                    />
                    {
                        type == OfferType.Flexible &&
                        <TextField
                            id="max_acceptable_debt"
                            inputProps={{ 'aria-label': 'Without label' }}
                            placeholder="Maximum Acceptable Debt %"
                            variant="outlined"
                            value={maxAcceptableDebt}
                            className={styles.formItem}
                            onChange={(e) => setMaxAcceptableDebt(Number(e.target.value))}
                        />
                    }
                    <div
                        className="mt-4"
                        onClick={() => {
                            setPopup(false);
                            getEstimate();
                            // navigate("/dashboard");
                        }}
                    >
                        <Button>OK</Button>
                    </div>
                </div>
            </section>

            <div className={styles.modal} onClick={() => setPopup(false)}></div>
        </>
    );
};

export default EstimateModal;
