  import React, { useState, useEffect } from "react";
  import MyRange from "../../../Range/Range";
  import styles from "./Popup.module.css";
  import {
    approveToken,
    getBalance
  } from "../../../../utilities/Contracts/OwnaToken/ownaTokenContract";
  import { CONTRACT_DETAILS } from "../../../../utilities/Contracts/contractsDetails";
  import {
    repayLoan,
    repayLoanByAdmin
  } from "../../../../utilities/Contracts/OwnaLendingAndBorrowing/ownaLendingAndBorrowingContractFunctions";
  import {
    handleRequest,
    calculateIfMaxAcceptableDebtIncreased
  } from "../../../../utilities/common.utility";
  import { toast } from "react-toastify";
  import Input from "../../../Input/Input";
  import { getWeb3 } from "../../../../utilities/web3.utility";
  import ModalButton from "../../../modalBtn/ModalBtn";

  interface RefundPopupProps {
    setPopup: React.Dispatch<React.SetStateAction<boolean>>;
    offerDetails: any;
    getBorrowedItems: () => void;
    borrowContractDetails: any;
    owna?: boolean;
    admin?: boolean;
    min: number;
    connect: string;
  }

  const RefundPopup = ({
    setPopup,
    offerDetails,
    getBorrowedItems,
    borrowContractDetails,
    owna,
    admin,
    min,
    connect
  }:RefundPopupProps) => {
    const [value, setValue] = useState([
      borrowContractDetails?.totalRepayDebt.toFixed(5)
    ]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [balance, setBalance] = useState(0);
    const [steps, setSteps] = useState({ step1: true, step2: false });
    
    useEffect(() => {
      readBalance();
    }, []);
  
    const handleRefundClick = async () => {
      try {
        setLoading(true);
        toast.info("Please do not close or reload page");
        const { amount, maximumacceptabledebt, interest, type } = offerDetails;
        if (type === "flexible" && !owna) {
          if (
            calculateIfMaxAcceptableDebtIncreased(
              amount,
              maximumacceptabledebt,
              interest
            )
          ) {
            toast.error(
              "Your asset is moved to the owna becauase interest is above the maximum acceptable debt. Amount: " + amount + " Maximum Acceptable Debt: " + maximumacceptabledebt + " Interest: " + interest
            );
            return updateBorrowStatusToSellIt();
          }
        }
        let amountToPay = undefined;
  
        if (
          +value[0] === Number(borrowContractDetails?.totalRepayDebt.toFixed(5))
        ) {
          amountToPay = borrowContractDetails?.amountOfBorrower;
        } else {
          amountToPay = value[0];
        }
        let amountToRepay =
          +value[0] === Number(borrowContractDetails?.totalRepayDebt.toFixed(5));
  
          const approvalOfRefund = await approveToken(
            CONTRACT_DETAILS?.borrowAndLending?.address,
            amountToPay.toString()
          );
  
          if (!approvalOfRefund?.status) {
            throw new Error(
              "Something went wrong while approving the refunded tokens."
            );
          }
  
        setSteps({ ...steps, step1: false, step2: true });
        const offerId =
          offerDetails?.type === "fixed"
            ? offerDetails?.fixedOfferId
            : offerDetails?.flexibleOfferId;
  
        let repayLoanResponse = null;
  
        if (!owna) {
          repayLoanResponse = await repayLoan(
            offerDetails.nftId,
            offerId,
            +value[0] === Number(borrowContractDetails?.totalRepayDebt.toFixed(5))
              ? amountToPay
              : amountToPay.toString(),
              amountToRepay
          );
        } else {
          try {
            repayLoanResponse = await repayLoanByAdmin(
              offerDetails.nftId,
              offerId
            );
          } catch (err) {
            console.log(err, "error=====>");
          }
        }
        if (repayLoanResponse?.status && repayLoanResponse?.transactionHash) {
          const responseUpdateBorrow = await handleRequest(
            "post",
            "borrow/updateBorrowStatus",
            {},
            {
              refundedAmount: Number(value[0]),
              borrowId: offerDetails?.borrowId,
              totalRepayDebt: Number(borrowContractDetails?.totalRepayDebt),
              interest: Number(offerDetails?.contractInterest),
              offerId: offerDetails?._id,
              dailyInterest: Number(offerDetails?.dailyInterest)
            }
          );
  
          if (responseUpdateBorrow?.data?.success) {
            getBorrowedItems();
            toast.success("Amount Refunded Successfully");
            return;
          }
        }
        toast.error("Something went wrong.");
        setSteps({ ...steps, step1: true, step2: false });
      } catch (err) {
        console.log("🚀 ~ file: RefundPopup.js ~ handleRefundClick ~ err", err);
        setSteps({ ...steps, step1: true, step2: false });
      } finally {
        setPopup(false);
        setLoading(false);
        setSteps({ ...steps, step1: true, step2: false });
      }
    };
  
    const updateBorrowStatusToSellIt = async () => {
      try {
        const responseUpdateBorrow = await handleRequest(
          "post",
          "borrow/updateBorrowStatus",
          {},
          {
            borrowId: offerDetails?.borrowId,
            status: "sellIt"
          }
        );
        if (responseUpdateBorrow.data.success) {
          getBorrowedItems();
        } else {
          toast.error(responseUpdateBorrow.data.message);
        }
      } catch (err:any) {
        toast.error(err);
      }
    };
  
    const readBalance = async () => {
      const web3 = await getWeb3();
      let balance = await getBalance(connect);
      balance = web3.utils.fromWei(balance.toString(), "mwei");
      setBalance(balance);
    };
  
    const handleBtnDisable = () => {
      if (
        +value[0] === Number(borrowContractDetails?.totalRepayDebt.toFixed(5))
      ) {
        if (+balance < Number(borrowContractDetails?.totalRepayDebt.toFixed(5))) {
          return true;
        } else {
          return false;
        }
      } else {
        return +balance < value[0];
      }
    };
  
    return (
      <>
        <section className={styles.popupWrapper}>
          <div className={`wrapper ${styles.popup}`}>
            <h2 className={styles.title}>Selected</h2>
            <p className={styles.tagline}>{value} USDC</p>
            <div className={styles.range}>
              {!admin && (
                <>
                  {error && (
                    <p className={styles.textAlert}>
                      {`Max value allowed ${borrowContractDetails?.totalRepayDebt}`}
                    </p>
                  )}
                  <Input
                    onChange={(e) => {
                      if (
                        +e.target.value >
                        borrowContractDetails?.totalRepayDebt.toFixed(5)
                      ) {
                        e.preventDefault();
                        setError(true);
                        return;
                      }
                      setError(false);
                      setValue([e.target.value]);
                    }}
                    value={value[0]}
                    type="number"
                    placeholder={"Enter Amount Manually"}
                  />
                </>
              )}
  
              <div className={styles.minMax}>
                <p className={styles.amount}>
                  {min} <span className={styles.text}>min</span>
                </p>
                <p className={styles.amount}>
                  {borrowContractDetails?.totalRepayDebt.toFixed(5)}{" "}
                  <span className={styles.text}>max</span>
                </p>
              </div>
              <MyRange
                value={value}
                setValue={setValue}
                min={min}
                disabled={admin ? true : false}
                max={borrowContractDetails?.totalRepayDebt.toFixed(5)}
              />
            </div>
            
            {!admin ? (
              <p>Balance:{Number(balance)?.toFixed(2)} USDC</p>
            ) : null}
  
            <div className={styles.buttonContainer}>
             
              <ModalButton
                btn1="Approve"
                btn2="Refund"
                btn1Handler={() => handleRefundClick()}
                steps={steps}
                loading={loading}
                disabled={
                  admin?false:value[0] === 0 || handleBtnDisable() || loading
                }
              />
            </div>
          </div>
        </section>
  
        <div
          className={styles.modal}
          onClick={() => {
            if (loading) {
              return;
            }
            setPopup(false);
          }}
        ></div>
      </>
    );
  };
  
  export default RefundPopup;
