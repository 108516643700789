
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SortIcon from '@mui/icons-material/Sort';
import { Box, Button, Switch, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import CategoryCheckbox from './categoryCheckbox';
import NetworkCheckbox from './networkCheckbox';

type Prop = {
    setActive: any;
    active: boolean
}

const useStyles = makeStyles((theme: any) => ({
    switchRoot: {
        '& .MuiSwitch-thumb': {
            background: 'linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%)',
        },
        '& .MuiSwitch-track': {
            background: 'linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%)',
        },
    },
}));

export default function Category({ setActive, active }: Prop) {
    const [expandedPanel, setExpandedPanel] = React.useState(null);
    const classes = useStyles();
    const handleChange = (panel: any) => (event: any, isExpanded: Boolean) => {
        setExpandedPanel(isExpanded ? panel : null);
    };

    const isPanelExpanded = (panel: String) => {
        return expandedPanel === panel;
    };

    return (
        <div className='border-gray-200 border-2 rounded-xl pt-4'>
            <div className='flex  gap-2 pl-4'>
                <SortIcon fontSize='small' />
                <p className="text-[0.875rem] font-semibold">Filter</p>
            </div>

            <div>
                <Accordion expanded={isPanelExpanded('panel1')} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={isPanelExpanded('panel1') ? <RemoveIcon /> : <AddIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{ fontSize: '0.875rem', fontWeight: '600', color: '#212529' }} >Category</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CategoryCheckbox />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={isPanelExpanded('panel2')} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={isPanelExpanded('panel2') ? <RemoveIcon /> : <AddIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ fontSize: '0.875rem', fontWeight: '600', color: '#212529' }}>Price Range</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ width: '100%', display: 'flex', gap: '5px' }} >
                            <TextField
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontSize: '14px',
                                    },
                                }}
                                id="outlined-basic" label="Min Price" variant="outlined" type='number' />
                            <TextField
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontSize: '14px',
                                    },
                                }}
                                id="outlined-basic" label="Max Price" variant="outlined" type='number' />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={isPanelExpanded('panel3')} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={isPanelExpanded('panel3') ? <RemoveIcon /> : <AddIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography sx={{ fontSize: '0.875rem', fontWeight: '600', color: '#212529' }}>Network</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <NetworkCheckbox />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={isPanelExpanded('panel3')} onChange={handleChange('panel3')}>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: '17px', py: '8px' }}>
                        <Typography sx={{ fontSize: '0.875rem', fontWeight: '600', color: '#212529' }} >Active Loans</Typography>
                        <Switch sx={{ color: 'linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%)' }}
                            classes={{
                                root: active ? classes.switchRoot : '',
                            }}
                            checked={active}
                            onChange={(e) => { setActive(!active) }} />

                    </Box>
                </Accordion>
                <Accordion expanded={isPanelExpanded('panel3')} onChange={handleChange('panel3')}>
                    <div className='w-full flex justify-center pt-4 mb-10'>
                        {/* <Button sx={{ borderRadius: '20px' }} color='inherit' variant="outlined">Clear</Button> */}
                        <div style={{ background: 'linear-gradient(90deg, #ca3d76 0%, #9f67bb 100%)', borderRadius: '30px', padding: '2px', width: '80%', margin: 'auto' }}>
                            <Button
                                sx={{
                                    borderRadius: '30px',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    height: '50px',
                                    color: '#AF5399',
                                    fontWeight: '600',
                                    ':hover': {
                                        color: 'white'
                                    }
                                }}
                            >
                                Clear
                            </Button>
                        </div>

                    </div>
                </Accordion>

            </div>


        </div>
    );
}
