import React from "react";
import styles from "./CheckBox.module.css";

const CheckBox = ({ id, label, checked, setChecked }:any) => {
  return (
    <div className={styles.container}>
      <input
        id={id}
        type="checkbox"
        value={checked}
        onChange={() => setChecked((prev:any) => !prev)}
        className={styles.myCheckBox}
      />
      <label className={styles.checkmark} htmlFor={id}>{label}</label>
    </div>
  );
};

export default CheckBox;
