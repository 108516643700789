import React, {useEffect, useState} from "react";
import ImageVideo from "./ImageVideo/ImageVideo";
import Input from "../../../components/Input/Input";
import Button from "../../Button/Button";
import {FiPlus} from "react-icons/fi";
import propertiesImage from "../../../images/propertise.svg";
import levelsImage from "../../../images/levels.svg";
import statsImage from "../../../images/stats.svg";
import styles from "./MintNft.module.css";
import LevelsModal from "./Modals/LevelsModal";
import {
    getMetamaskConnectedAddress,
    getChainId,
    getNetwork,
} from "../../../utilities/web3.utility";
import {useDataContext} from "../../../context/context";
import {
    handleRequest,
    validateIfProfileIsUpdated,
} from "../../../utilities/common.utility";
import {toast} from "react-toastify";
import {mintOwnaNft} from "../../../utilities/Contracts/Minting/mintingContract";
import {useNavigate} from "react-router-dom";
import {formData} from "../../../utilities/data";
import {ENV} from "../../../config/config";
import UploadingPhoto from "../../UploadingPhoto/UploadingPhoto";

interface Property {
    name: string;
    type: string;
}

interface FormInputs {
    ownerAddress: string;
    fromAddress: string;
    file: string | Blob | null;
    title: string;
    email: string;
    externalLink: string;
    description: string;
    maxBorowCapacity: string;
    entryFee: string;
    additionalDoc: string;
    properties: Property[];
    levels: Property[];
    stats: Property[];
    status: string;
}

const MintNft: React.FC = () => {
    const {setConnect, connect}: any = useDataContext();
    const navigate = useNavigate();
    const [properties, setProperties] = useState(false);
    const [levels, setLevels] = useState(false);
    const [stats, setStats] = useState(false);
    const [propertiesInfo, setPropertiesInfo] = useState<Property[]>([
        {name: "", type: ""},
    ]);
    const [levelsInfo, setLevelsInfo] = useState<Property[]>([
        {name: "", type: ""},
    ]);
    const [statsInfo, setStatsInfo] = useState<Property[]>([
        {name: "", type: ""},
    ]);
    const [loader, setLoader] = useState(false);
    const [getValues, setgetValues] = useState({
        getCurrentAddress: '',
        isLoading: false,
    });
    const [isValidNetwork, setIsValidNetwork] = useState(true);
    const [formInputs, setformInputs] = useState<any>({
        ownerAddress: "",
        fromAddress: "",
        origin: "",
        facing: "",
        back: "",
        leftF: "",
        rightF: "",
        title: "",
        email: "",
        externalLink: "",
        description: "",
        maxBorowCapacity: "",
        entryFee: "2",
        additionalDoc: "",
        properties: [],
        levels: [],
        stats: [],
        status: "lending",
    });

    interface InfoType {
        image: string;
        title: string;
        text: string;
        setModal: React.Dispatch<React.SetStateAction<boolean>>;
    }

    const info: InfoType[] = [
        {
            image: propertiesImage,
            title: "Propertise",
            text: "Textual traits that show up as rectangles",
            setModal: setProperties,
        },
        {
            image: levelsImage,
            title: "Levels",
            text: "Numerical traits that show as a progress bar",
            setModal: setLevels,
        },
        {
            image: statsImage,
            title: "Stats",
            text: "Numerical traits that just show as numbers",
            setModal: setStats,
        },
    ];

    useEffect(() => {
        getWalletAddress();
        validateNetwork();
        // @ts-ignore
        window?.ethereum?.on("chainChanged", async () => {
            if (await validateNetwork()) {
                setIsValidNetwork(true);
            } else {
                setIsValidNetwork(false);
            }
        });
    }, []);

    useEffect(() => {
        if (connect) {
            setformInputs({
                ...formInputs,
                fromAddress: connect,
            });
        }
    }, [connect]);

    const getWalletAddress = async () => {
        const address = await getMetamaskConnectedAddress();
        if (address) {
            setgetValues({
                ...getValues,
                getCurrentAddress: address,
            });
            setConnect(address);
        }
    };

    const uploadDataOnIpfs = async (e: any) => {
        if (!validateIfProfileIsUpdated()) {
            toast.info("Please complete profile first");
            return navigate("/myprofile");
        }
        e.preventDefault();
        if (!isValidEmail()) {
            return toast.error("Please enter a valid email address");
        }
        try {
            toast.info("Please do not close or reload page");

            setLoader(true);
            const formData = new FormData();
            const payload: any = {...formInputs};
            payload.properties = JSON.stringify(payload.properties);
            payload.levels = JSON.stringify(payload.levels);
            payload.stats = JSON.stringify(payload.stats);

            for (const val in payload) {
                formData.append(val, payload[val]);
            }
            const res = await handleRequest("post", "mint/ipfs/upload", formData as any);

            if (res.data.success) {
                toast.success("Data uploaded on IPFs Successfully");
                const mintRes = await mintOwnaNft(
                    formInputs.ownerAddress,
                    formInputs.title,
                    res.data.data.metaDataUrl
                );
                const data = {...res.data.data};
                data.tokenId = +mintRes.events.Transfer.returnValues.tokenId;
                createNFT(data);
            } else {
                toast.error(res.data.message);
                setLoader(false);
            }
        } catch (err: any) {
            toast.error(err.message);
            setLoader(false);
            console.log(
                "🚀 ~ file: MintNft.js ~ line 171 ~ uploadDataOnIpfs ~ err",
                err
            );
        } finally {
            setLoader(false);
        }
    };
    const handleModalsOnChange = (name: any, data: any) => {
        setData(name, data);
    };
    const setData = (name: any, data: any) => {
        if (name === "properties") {
            setPropertiesInfo(data);
        } else if (name === "levels") {
            setLevelsInfo(data);
        } else if (name === "stats") {
            setStatsInfo(data);
        }
    };
    const handleModalsAddMore = (name: any, data: any) => {
        setData(name, data);
    };
    const onSave = (name: any, data: any) => {
        setformInputs({
            ...formInputs,
            [name]: data,
        });
    };

    const createNFT = async (data: any) => {
        const res = await handleRequest("post", "mint/add", data);
        if (res.data.success) {
            setformInputs({
                ownerAddress: "",
                origin: "",
                facing: "",
                back: "",
                leftF: "",
                rightF: "",
                title: "",
                email: "",
                externalLink: "",
                description: "",
                collection: "",
                maxBorowCapacity: "",
                entryFee: "",
                additionalDoc: "",
                properties: [],
                levels: [],
                stats: [],
                status: "lending",
            });
            setStatsInfo([{name: "", type: ""}]);
            setLevelsInfo([{name: "", type: ""}]);
            setPropertiesInfo([{name: "", type: ""}]);
            toast.success("NFT minted successfully");
            setLoader(false);
            navigate("/sendestimate");
        } else {
            toast.error(res.data.message);
            setLoader(false);
        }
    };

    const handleBtnDisable = () => {
        let fieldsToValidate = JSON.parse(JSON.stringify(formInputs));

        delete fieldsToValidate.externalLink;
        delete fieldsToValidate.description;

        const isEmpty = Object.values(fieldsToValidate).some(
            (val, i) => val === null || val === ""
        );
        return isEmpty;
    };

    const validateNetwork = async () => {
        const chainId = await getChainId();
        if (ENV.chainId !== chainId || !connect) {
            setIsValidNetwork(false);
            return false;
        } else {
            setIsValidNetwork(true);

            return true;
        }
    };

    const isValidEmail = () => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            formInputs.email
        );
    };
    return (
        <div className={`wrapper ${styles.mintNft}`}>
            <h1 className="title">Create New Item</h1>
            {(!connect || !isValidNetwork) && (
                <span className={styles.connectWalletMessage}>
          {`Please connect your wallet with ${getNetwork(ENV.chainId).name}`}
        </span>
            )}
            <form onSubmit={uploadDataOnIpfs}>
                <Input
                    label="Wallet Address*"
                    type="text"
                    name="ownerAddress"
                    placeholder="Wallet Address"
                    minLength={42}
                    maxLength={42}
                    value={formInputs.ownerAddress}
                    onChange={(e: any) => {
                        setformInputs({
                            ...formInputs,
                            ownerAddress: e.target.value,
                        });
                    }}
                />
                <UploadingPhoto
                    values={formInputs}
                    onChange={(name: string, e: any) => {
                        setformInputs({...formInputs, [name]: e.target.files[0]});
                    }}
                />
                {formData.map((el, i) => {
                    return (
                        <Input
                            onChange={(e: any) => {
                                setformInputs({
                                    ...formInputs,
                                    [el.name]: e.target.value,
                                });
                            }}
                            {...el}
                            key={i}
                            value={formInputs[el.name]}
                            required={el.required}
                        />
                    );
                })}
                {info.map((el, i) => (
                    <div className={styles.info} key={i}>
                        <div className={styles.infoWrapper}>
                            <div className={styles.iconAndContainer}>
                                <img src={el.image} alt="#" className={styles.icon}/>
                                <div className={styles.titleAndText}>
                                    <h5 className={styles.title}>{el.title}</h5>{" "}
                                    <p className={styles.text}>{el.text} </p>
                                </div>
                            </div>
                            <div
                                className={styles.plusWrapper}
                                onClick={() => {
                                    el.setModal(true);
                                    window.scrollTo({top: 0});
                                }}
                            >
                                <FiPlus className={styles.plus}/>
                            </div>
                        </div>
                    </div>
                ))}
                <div className={styles.labelContainer}>
                    <label className={`${styles.label} ${styles.text}`}>
                        {" "}
                        Additional Document*
                    </label>
                    <span className={styles.fileName}>
            <label htmlFor="additionalDoc" className={styles.button}>
              Upload
              <input
                  type="file"
                  id="additionalDoc"
                  name="additionalDoc"
                  onChange={(e: any) => {
                      setformInputs({
                          ...formInputs,
                          additionalDoc: e.target.files[0],
                      });
                  }}
                  className={styles.input}
              />
            </label>
                        {formInputs?.additionalDoc && (
                            <p className={styles.filetext}>
                                {formInputs?.additionalDoc?.name}
                            </p>
                        )}
          </span>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        cursor={getValues.isLoading ? "no-drop" : false}
                        disabled={handleBtnDisable()}
                        type="submit"
                        loader={loader}
                    >
                        Submit
                    </Button>
                </div>
                {" "}
            </form>
            {properties && (
                <LevelsModal
                    setModal={setProperties}
                    formInputs={setformInputs}
                    fields={propertiesInfo}
                    handleModalsOnChange={handleModalsOnChange}
                    handleModalsAddMore={handleModalsAddMore}
                    label="properties"
                    onSave={onSave}
                    title="Add Properties"
                    description={"Textual traits that show up as rectangles"}
                />
            )}{" "}
            {levels && (
                <LevelsModal
                    setModal={setLevels}
                    formInputs={setformInputs}
                    fields={levelsInfo}
                    handleModalsOnChange={handleModalsOnChange}
                    handleModalsAddMore={handleModalsAddMore}
                    label="levels"
                    onSave={onSave}
                    title="Add Levels"
                    description={"Numerical traits that show as a progress bar"}

                />
            )}{" "}
            {stats && (
                <LevelsModal
                    setModal={setStats}
                    formInputs={setformInputs}
                    fields={statsInfo}
                    handleModalsOnChange={handleModalsOnChange}
                    handleModalsAddMore={handleModalsAddMore}
                    label="stats"
                    onSave={onSave}
                    title="Add Stats"
                    description={"Numerical traits that just show as numbers"}

                />
            )}
        </div>
    );
};

export default MintNft;
