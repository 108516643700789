import React, { useState } from "react";
import Input from "../../../Input/Input";
import Button from "../../../Button/Button";
import styles from "./style.module.css";

const LevelsModal = ({
  setModal,
  title,
  description,
  fields,
  handleModalsOnChange,
  handleModalsAddMore,
  onSave,
  label,
}:any) => {
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    email: "",
    walletaddress: "",
    id: "",
    proofofaddress: "",
  });
  const onChange = (e:any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleChange = (i:any, e:any, status?:any) => {
    const newFormValues = [...fields];
    newFormValues[i][e.target.name] = e.target.value;
    handleModalsOnChange(label, newFormValues);
  };
  const handleAddMore = () => {
    const addProperties = [...fields, { name: "", type: "" }];
    handleModalsAddMore(label, addProperties);
  };
  const handleSubmit = (name:any, e:any) => {
    e.preventDefault();
    onSave(name, fields);
    setModal(false);
  };

  return (
    <>
      <section className={styles.popupWrapper}>
        <div className={`wrapper ${styles.popup}`}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.tagline}>
        {description}
          </p>
          <form
            // action="#"
            onSubmit={(e) => handleSubmit(label, e)}
            className={styles.form}
          >
            {fields &&
              fields.length > 0 &&
              fields.map((input:any, i:any) => (
                <div key={"level_" + i}>
                  <Input
                    name="type"
                    label="Type"
                    placeholder="Type"
                    value={input.type}
                    required={true}
                    maxLength={40}
                    onChange={(e:any) => handleChange(i, e)}
                  />

                  <Input
                    name="name"
                    label="Name"
                    placeholder="Name"
                    value={input.name}
                    required={true}
                    maxLength={40}
                    onChange={(e:any) => handleChange(i, e)}
                  />
                </div>
              ))}
            <div className={styles.buttonContainer}>
              <div onClick={() => handleAddMore()}>
                <Button
                  background="#E7E7E7"
                  color="#323232"
                >
                  Add More
                </Button>
              </div>
              <div>
                <Button type="submit">Save</Button>
              </div>
            </div>
          </form>
        </div>
      </section>

      <div className={styles.modal} onClick={() => setModal(false)}></div>
    </>
  );
};

export default LevelsModal;
