import React from "react";
import Button from "../../Button/Button";
import styles from "./WhatAreYouWillingTodo.module.css";
import { handleRequest } from "../../../utilities/common.utility";
import { toast } from "react-toastify";
import { useDataContext } from "../../../context/context";
import { useNavigate } from "react-router-dom";

const WhatAreYouWillingTodo = () => {
  const [appointment, setAppointment] = React.useState(null);
  const { connect }:any = useDataContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (connect) {
      getAppointment(connect);
    }
  }, [connect]);

  const getAppointment = async (connect:any) => {
    try {
      const res = await handleRequest("get", `appointments/get/${connect}`);
      if (res.data.success) {
        const { data } = res.data;
        setAppointment(data);
      } else {
        toast.error(res.data.message);
      }
    } catch (err:any) {
      toast.error(err);
    }
  };

  const getAssetBack = async () => {
    try {
      const res = await handleRequest(
        "put",
          // @ts-ignore
        `appointments/update/${appointment!.walletAddress}`
      );
      if (res.data.success) {
        navigate("/connect");
      } else {
        toast.error(res.data.message);
      }
    } catch (err:any) {
      toast.error(err);
    }
  };
  return (
    <section className={` ${styles.willingTodo}`}>
      <h1 className={`title ${styles.title}`}>
        Hi there! What are you willing to do ?
      </h1>
      <div className={styles.buttonContainer}>
        {appointment && (
          <Button
            click={() => getAssetBack()}
            color="#323232"
            background="#E7E7E7"
          >
            Get asset back
          </Button>
        )}{" "}
        <Button to="/appointment">Bring asset</Button>
      </div>
    </section>
  );
};

export default WhatAreYouWillingTodo;
