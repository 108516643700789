const KYCInputData = [
  {
    label: "First Name*",
    type: "text",
    name: "firstName",
    minLength: 3,
    maxLength: 25,
    required: true,
    placeholder: "John Doe",
  },
  {
    label: "Last Name*",
    type: "text",
    name: "lastName",
    minLength: 3,
    maxLength: 25,
    required: true,
    placeholder: "John Doe",
  },
  {
    label: "email*",
    type: "email",
    name: "email",
    maxLength: 40,
    required: true,
    placeholder: "john.doe@awsamplify.com",
  },
  {
    label: "Wallet Address*",
    type: "text",
    walletAddress: true,
    required:true,
    name: "walletAddress",
    placeholder: "Seattle, WA",
  },
];

const formData = [
  {
    label: "Nft Title*",
    type: "text",
    name: "title",
    placeholder: "Nft Title",
    minLength: 3,
    maxLength:25,
    required: true,
  },
  {
    label: "Email*",
    type: "email",
    name: "email",
    placeholder: "Email",
    maxLength:40,
    required: true,
  },
  {
    label: "External Links",
    type: "url",
    name: "externalLink",
    placeholder: "External Link",
    maxLength:50,
    required: false,
  },
  {
    label: "Description",
    type: "text",
    name: "description",
    placeholder: "Seattle, WA",
    minLength: 3,
    maxLength:120,
    required: false,
  },
  {
    label: "Max Borrowing Capacity*",
    type: "number",
    name: "maxBorowCapacity",
    min:1,
    max:90000000000,
    placeholder: "maxBorowCapacity",
    required: true,
  },
  {
    label: "Entry Fee*",
    type: "number",
    name: "entryFee",
    placeholder: "entryFee",
    background:"#f7f7f9",
    disable:true,
    required: true,
  },
];

export { KYCInputData, formData };
