import React from "react";

import { InlineWidget } from "react-calendly";

import styles from "./LetsConnect.module.css";
import map from "../../../images/map.png";
const LetsConnect = () => {
  return (
    <section className={` ${styles.letsConnectWrapper}`}>
      <div className="container">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className={styles.letsConnect}>
            <div>
              <h1 className="title">Let’s Connect</h1>
              <InlineWidget url="https://calendly.com/ownafinance/30min" />
            </div>
            <div className={styles.addressInfo}>
              <div>
                <span className="font-weight-bold">
                  Addresse:{" "}
                  <span className={styles.addressText}>
                    1 Avenue de l'Observatoire 75006 Paris France
                  </span>
                </span>
              </div>

              <div className="my-4">
                <span>
                  Opening hours:{" "}
                  <span className={styles.addressText}>
                    Monday to Saturday, 09 am to to 9 pm.
                  </span>
                </span>
              </div>

              <div className="my-4">
                <span>
                  Beware:{" "}
                  <span className={styles.addressText}>
                    All borrowers have to know the last 4 digits of their
                    <br/>
                    Wallet IF and their real ID card
                  </span>
                </span>
              </div>
              <div className={styles.imgContainer}>
                <img src={map} alt="map" />
              </div>
            </div>
          </div>
          <div className={styles.descriptionWrapper}>
            <div className={styles.letsConnectDescription}>
              Our auctioneers are at your disposal to come to your home in order to carry out any inventory with a view
  to partition or insurance.
            </div>
            <div className={styles.letsConnectDescription}>
              At your request and after sending photographs, they can draw up a preliminary estimate.
  Following their visit, a written report will be sent to you in paper and digital form.
            </div>
            <div className={styles.letsConnectDescription}>
              In order to book an appointment please send us an email at contact@owna.io
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LetsConnect;
