const CryptoJS = require("crypto-js");
export const ENV = {
    url: process.env.REACT_APP_BASE_URL,
    chainId: +process.env.REACT_APP_CHAINID!,
    admin: process.env.REACT_APP_ADMIN_WALLET,
    mint: process.env.REACT_APP_MINT_CONTRACT,
    borrowLending: process.env.REACT_APP_BORROWLENDING_CONTRACT,
    ownaToken: process.env.REACT_APP_OWNATOKEN,
    dataEncryptionKey: process.env.REACT_APP_OWNATOKEN_SECRET_KEY_FOR_ENCRYPTION,
    isProduction: process.env.REACT_APP_CHAINID! == '1101',

    encryptUserData: function (data: any) {
        const userData = localStorage.getItem("encuse");
        if (userData && !data.accessToken) {
            const bytes = CryptoJS.AES.decrypt(userData, process.env.REACT_APP_OWNATOKEN_SECRET_KEY_FOR_ENCRYPTION);
            const originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (originalData && originalData.accessToken) {
                data.accessToken = originalData.accessToken;
            }
        }
        data = JSON.stringify(data);
        const encryptedUser = CryptoJS.AES.encrypt(
            data,
            process.env.REACT_APP_OWNATOKEN_SECRET_KEY_FOR_ENCRYPTION
        ).toString();
        localStorage.setItem("encuse", encryptedUser);
        return true;
    },

    getUserKeys: function (keys: any) {
        const userData = localStorage.getItem("encuse");
        if (userData) {
            const bytes = CryptoJS.AES.decrypt(userData, process.env.REACT_APP_OWNATOKEN_SECRET_KEY_FOR_ENCRYPTION);
            const originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            let user: { [key: string]: any } = {};
            if (keys) {
                keys = keys.split(" ");
                for (const key in keys) {
                    const keyV = keys[key];
                    user[keyV] = originalData[keyV];
                }
            } else {
                user = originalData;
            }
            return user;
        }
        return {};
    },
};
