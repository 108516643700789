import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../../Button/Button";
import Popup from "../BorrowPopUp/BorrowPopup";
import styles from "./P2pComponents.module.css";
import {
  handleRequest,
  validateIfProfileIsUpdated
} from "../../../../utilities/common.utility";
import { useDataContext } from "../../../../context/context";
import { toast } from "react-toastify";
import { borrowLoan } from "../../../../utilities/Contracts/OwnaLendingAndBorrowing/ownaLendingAndBorrowingContractFunctions";
import { approveToken } from "../../../../utilities/Contracts/OwnaToken/ownaTokenContract";
import { CONTRACT_DETAILS } from "../../../../utilities/Contracts/contractsDetails";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

const P2pComponents = ({ data, getOffers }:any) => {
  const { connect, scrollToTop, refetch, setRefetch }:any = useDataContext();
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [steps, setSteps] = useState({ step1: true, step2: false });
  useEffect(() => {}, []);
  const acceptOffer = async (amount:any) => {
    try {
      if (!validateIfProfileIsUpdated()) {
        toast.info("Please complete profile first");
        return navigate("/myprofile");
      }
      setLoader(true);
      toast.info("Please do not close or reload page");

      //get offerId
      const offerId =
        data?.offerDetails?.type === "fixed"
          ? data?.offerDetails?.fixedOfferId
          : data?.offerDetails?.flexibleOfferId;

      //approve tokens    
      const approvalOfTokens = await approveToken(
        CONTRACT_DETAILS?.borrowAndLending?.address,
        amount.toString()
      );
      if (!approvalOfTokens?.status) {
        throw new Error("Something went wrong while approving the tokens");
      }
      setSteps({ ...steps, step1: false, step2: true });

      //borrow loan from contractt side
      const borrowLoanResponse = await borrowLoan(
        data?.offerDetails?.mintId?.tokenId,
        offerId,
        amount.toString(),
        data?.offerDetails?.type
      );

      if (!borrowLoanResponse?.status) {
        throw new Error("Something went wrong while borrwoing loan.");
      }
      const {
        mintId,
        userId,
        _id,
        type,
        fromAddress,
        period,
        apr,
        maximumacceptabledebt
      } = data.offerDetails;
      let payload = {
        amount,
        mintId,
        userId,
        offerId: _id,
        type,
        ownerAddress: connect?.toLowerCase(),
        lenderAddress: fromAddress,
        period,
        apr,
        maximumacceptabledebt
      };
      const res = await handleRequest("post", "offer/acceptOffer", payload as any);
      if (res.data.success) {
        toast.success("Congratulations you have borrowed the offer");
        setRefetch(!refetch);
        setLoader(false);
        setPopup(false);
        getOffers();
      } else {
        toast.error(res.data.message);
        setLoader(false);
        setSteps({ ...steps, step1: true, step2: false });
      }
    } catch (err) {
      setLoader(false);
      setSteps({ ...steps, step1: true, step2: false });
    }
  };

  return (
    <>
      <div className={`${styles.wrapper} wrapperBorder`}>
        <div className={styles.dataWrapper}>
          {data.info.map((item:any, index:number) => (
            <div
              key={index}
              className={`${styles.textContainer} ${
                index % 2 === 0 && styles.textContainerBorder
              }`}
            >
              <div>
                <p className={styles.title}>{item.title}</p>
                {item?.copy ? (
                  <CopyToClipboard
                    onCopy={() => toast.success("Address copied successfully")}
                    text={item.copy}
                  >
                    <p className={styles.text} style={{ cursor: "pointer" }}>
                      {item.text}
                    </p>
                  </CopyToClipboard>
                ) : (
                  <p className={styles.text}>{item.text}</p>
                )}
              </div>
            </div>
          ))}
        </div>{" "}
        <div
          className={styles.buttonContainer}
          onClick={() => {
            scrollToTop();
            setPopup(true);
          }}
        >
          <Button>Borrow</Button>
        </div>
      </div>
      {popup && (
        <Popup
          setPopup={setPopup}
          acceptOffer={acceptOffer}
          offerDetails={data?.offerDetails}
          loader={loader}
          steps={steps}
        />
      )}
    </>
  );
};

export default P2pComponents;
