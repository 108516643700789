import {useEffect} from "react";
import axios from "axios";
import {ENV} from "../config/config";
import {useDataContext} from "../context/context";
import {toast} from "react-toastify";
import Web3 from "web3";
import CryptoJS from "crypto-js";
import {getChainId} from "./web3.utility";

export const handleRequest = async (
    method: string,
    endpoint: string,
    data: object | null = null,
    params: object | null = null,
) => {
    let url = ENV.url + endpoint;
    let headers = {
        "x-auth-token": window.localStorage.getItem("authToken")
    };
    return await axios({
        method,
        url,
        ...(data && {data}),
        headers,
        ...(params && {params})
    });
};
export const ListenMetaMaskAccs = () => {
    const {setConnect}: any = useDataContext();

    useEffect(() => {
        try {
            if (!window?.ethereum) {
                return;
            }

            const accountWasChanged = async (data: any) => {
                const connectedChainId = await getChainId();
                if (ENV.chainId === connectedChainId) {
                    if (data.length === 0) {
                        return toast.error("you are not connected with metamask");
                    }
                    setConnect(data[0]);
                }
            };

            window?.ethereum.on("accountsChanged", accountWasChanged);
            window?.ethereum.on("chainChanged", (chainId: any) => {
                if (Web3.utils.hexToNumber(chainId) !== ENV.chainId) {
                    localStorage.clear();
                }
            });

            return () => {
                // unexpected empty errow function
            };
        } catch (err) {
            console.log(err, "error==>");
        }
    }, []);
};

export const percentage = (num: any, percantToGet: any) => {
    return (percantToGet / 100) * num;
};

export const isWhatPercentOf = (x: number, y: number) => {
    return (x / y) * 100;
};

export const calculateFlexibleDays = ({ loanMaxValue, apr, maximumacceptabledebt }: any) => {
    const twoPercant = percentage(+loanMaxValue, 2); //
    const aprPercantage = percentage(+loanMaxValue, +apr);
    const dailyCumulatedInterestRate = aprPercantage / 365;
    const maxBorrow =
        percentage(+loanMaxValue, +maximumacceptabledebt) +
        percentage(+loanMaxValue, 2); //
    let days = maxBorrow - twoPercant;
    days = days / dailyCumulatedInterestRate;
    days = Math.floor(Number(days.toFixed(2)));
    return days;
};

export const calculateIfMaxAcceptableDebtIncreased = (
    amount: number,
    maximumacceptabledebt: any,
    interest: any
) => {
    const maxiMumAcceptableDebt = percentage(+amount, +maximumacceptabledebt);

    if (+interest > maxiMumAcceptableDebt) {
        return true;
    } else {
        return false;
    }
};

export const validateIfProfileIsUpdated = () => {
    let foundUser = ENV.getUserKeys("lastName firstName walletAddress email");
    const {firstName, lastName, walletAddress, email} = foundUser;
    if (firstName && lastName && walletAddress && email) {
        return true;
    } else {
        return false;
    }
};

export const decryptData = (ciphertext: string) => {
    // Decrypt the data
    const key = ENV.dataEncryptionKey;
    const bytes = CryptoJS.AES.decrypt(ciphertext, key as string);

    // Can be zero bytes
    let decryptedData = {};
    try {
        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {}

    // Use the decrypted data
    return decryptedData;
};

export const handleScroll = (event:any) => {
    event?.currentTarget?.blur();
};