import React, {useEffect, useState} from "react";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import {BsChevronDown, BsChevronUp} from "react-icons/bs";
import styles from "./SendOffer.module.css";
import {toast} from "react-toastify";
import {handleRequest} from "../../../utilities/common.utility";

const SendOffer = () => {
  const [open, setOpen] = useState(0);
  const [values, setValues] = useState<any>({
    period: "",

    data: [
      {
        entryfee: "2",
        apr: "",
        loanMinValue: "",
        loanMaxValue: "",
      },
      {
        entryfee: "2",
        apr: "",
        loanMinValue: "",
        loanMaxValue: "",
        maximumacceptabledebt: "",
      },
    ],
  });
  const inputs = [
    {
      fixed: true,
      title: "Fixed",
      info: [
        {
          label: "Entry fee %",
          type: "text",
          name: "entryfee",
          value: 2,
          disable: true,
          placeholder: "John Doe",
        },
        {
          label: "APR",
          type: "text",
          name: "apr",
          placeholder: "John Doe",
        },
        {
          label: "Loan Min value",
          type: "text",
          name: "loanMinValue",
          placeholder: "John Doe",
        },
        {
          label: "Loan Max value",
          type: "text",
          name: "loanMaxValue",
          placeholder: "John Doe",
        },
      ],
    },
    {
      title: "Flexible",
      info: [
        {
          label: "Entry fee %",
          type: "text",
          name: "entryfee",
          value: 1,
          placeholder: "John Doe",
          disable: true,
        },
        {
          label: "APR",
          type: "text",
          name: "apr",
          placeholder: "John Doe",
        },
        {
          label: "Loan Min value",
          type: "text",
          name: "loanMinValue",
          placeholder: "John Doe",
        },
        {
          label: "Loan Max value",
          type: "text",
          name: "loanMaxValue",
          placeholder: "John Doe",
        },
        {
          label: "Maximum acceptable Debt % (YDT)",
          type: "text",
          name: "maximumacceptabledebt",
          placeholder: "John Doe",
        },
      ],
    },
  ];

  const handleSendOffer = async () => {
    try {
      const { data, ...otherValues } = values;
      const payload = {
        ...otherValues,
        type: open === 0 ? "fixed" : "flexible",
        status: "pending",
      };
      // @ts-ignore
      const addingOffer = await handleRequest("post", "offer/addOffer", {
        payload,
      });

      if (addingOffer?.data?.success) {
        toast.success(addingOffer?.data?.message || "Offer Sent");
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: MakeOfferPopup.ts ~ line 118 ~ handleSubmitOffer ~ err",
        err
      );
    }
  };

  // @ts-ignore
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const showOpen = () => {
    if (open === 0) {
      setOpen(1);
    }
    if (open === 1) {
      setOpen(0);
    }
  };
  const [period, setPeriod] = useState<any>("");
  const [periodVisible, setPeriodVisible] = useState(false);
  const [customPeriod, setCustomPeriod] = useState(false);
  const periods = [7, 14, 30, 60, 90];

  useEffect(() => {
    if (period && period !== "" && open === 0) {
      setValues({ ...values, period });
      return;
    }
    if (open === 1) {
      setValues({ ...values, period: undefined });
      return;
    }
  }, [period, open]);

  return (
    <section className={`wrapper ${styles.estimate}`}>
      <form action="#">
        <Input
          label="Wallet Address"
          type="text"
          name="walletaddress"
          placeholder="John Doe"
          value={values["walletaddress"]}
          onChange={onChange}
        />
        {inputs.map((items, i) => (
          <div className={styles.dataWrapper} key={i}>
            <div className={styles.titleContainer}>
              <h2 className={`${styles.title} title`}>{items.title} </h2>
              {open === i ? (
                <BsChevronUp
                  className={`${styles.icon} ${styles.title}`}
                  onClick={showOpen}
                />
              ) : (
                <BsChevronDown
                  className={`${styles.icon} ${styles.title}`}
                  onClick={() => {
                    showOpen();
                  }}
                />
              )}
            </div>
            {open === i && (
              <form action="#">
                {items.fixed && (
                  <div className={styles.periodAndDuration}>
                    {" "}
                    <div className={styles.labelContainer}>
                      <p className={styles.label}>Set Period</p>
                      <div
                        className={styles.categoryContainer}
                        onClick={() => setPeriodVisible((prev) => !prev)}
                      >
                        <div className={styles.dropdownAndIcon}>
                          {customPeriod ? (
                            <input
                              className={styles.periodInput}
                              type="text"
                              placeholder="Enter Period"
                              value={values["period"]}
                              onChange={onChange}
                              name="period"
                            />
                          ) : (
                            <p className={styles.category}>
                              {period ? (
                                `${period} Days`
                              ) : (
                                <span className={styles.placeholeder}>
                                  Select
                                </span>
                              )}
                            </p>
                          )}
                          {periodVisible ? (
                            <BsChevronUp
                              className={styles.arrow}
                              onClick={() => setPeriodVisible((prev) => !prev)}
                            />
                          ) : (
                            <BsChevronDown
                              className={styles.arrow}
                              onClick={() => {
                                setPeriodVisible((prev) => !prev);
                                setCustomPeriod(false);
                              }}
                            />
                          )}
                        </div>
                        {periodVisible && (
                          <div className={styles.categories}>
                            {periods.map((el, i) => (
                              <p
                                className={styles.categoryItem}
                                key={i}
                                onClick={() => {
                                  setPeriod(el);
                                  setPeriodVisible((prev) => !prev);
                                }}
                              >
                                {el}
                              </p>
                            ))}
                            <p
                              className={styles.categoryItem}
                              onClick={() => {
                                setPeriodVisible((prev) => !prev);
                                setCustomPeriod((prev) => true);
                              }}
                            >
                              Custom Period
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {items.info.map((input, i) => (
                  <Input
                    {...input}
                    key={i}
                    value={values.data[items.title === "fixed" ? 0 : 1][input.name]}
                    onChange={onChange}
                    input={input}
                  />
                ))}
              </form>
            )}
          </div>
        ))}
      </form>
      <div className={styles.buttonContainer} onClick={handleSendOffer}>
        <Button>Send Offer</Button>
      </div>
    </section>
  );
};

export default SendOffer;
