import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

export default function NetworkCheckbox() {
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', borderTop: '1px solid grey', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="POLYGON ZKEVM"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="POLYGON ZKEVM"
                    labelPlacement="end"
                />
            </Box>
            <Box sx={{ width: '100%', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="POLYGON"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="POLYGON"
                    labelPlacement="end"
                />
            </Box>
            <Box sx={{ width: '100%', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="ETHEREUM"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="ETHEREUM"
                    labelPlacement="end"
                />
            </Box>
        </Box>
    )
}
