import {
  getContractMethods,
  getWeb3,
  getGasPriceFromStation
} from "../../web3.utility";
import { CONTRACT_DETAILS } from "../contractsDetails";

const contractDetails = CONTRACT_DETAILS.ownaToken;

const approveToken = async (spender :any, amount:any) => {
  try {
    const web3 = await getWeb3();
    const amountWei = web3.utils.toWei(amount, "mwei");

    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId()
    ]);

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );

    const allowance = await contract.methods
      .allowance(accounts[0], spender)
      .call({ from: accounts[0] });
   
    if (Number(allowance) >= Number(amountWei)) {
      return { status: true };
    }

    const estimatedGasFees = await contract.methods
      .approve(spender, amountWei)
      .estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    const responsFixedLoanOffer = await contract.methods
      .approve(spender, amountWei)
      .send({
        from: accounts[0],
        gas: parseInt(String(estimatedGasFees * 1.1)),
        gasPrice: gasPriceFromStation
      });

    return responsFixedLoanOffer;
  } catch (err) {
    console.log("🚀 ~ file: ownaTokenContract.js ~ approveToken ~ err", err);
  }
};

const getBalance = async (address :string) => {
  try {
    const web3 = await getWeb3();

    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId()
    ]);

    const contract = await getContractMethods(
      contractDetails.ABI,
      chainId && contractDetails.address
    );

    const balance = await contract.methods
      .balanceOf(address)
      .call({ from: accounts[0] });

    return balance;
  } catch (err) {
    console.log("🚀 ~ file: ownaTokenContract.js ~ getBalance ~ err", err);
  }
};

export { approveToken, getBalance };
