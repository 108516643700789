export enum OfferStatus {
    Pending = "pending",
    Accepted = "accepted",
    Cancelled = "cancelled",
    Rejected = "rejected",
    Claimed = "claimed",
}

export enum OfferBorrowStatus {
    Borrowed = "borrowed",
    SellIt = "sellIt",
    Refunded = "refunded",
}

export const OfferStateLabelMap: { [key: string]: string } = {
    [OfferStatus.Pending]: 'Pending',
    [OfferStatus.Accepted]: 'Accepted',
    [OfferStatus.Cancelled]: 'Cancelled',
    [OfferStatus.Rejected]: 'Rejected',
    [OfferStatus.Claimed]: 'Claimed',
}

export enum OfferType {
    Fixed = "fixed",
    Flexible = "flexible",
}

export const OfferTypeLabelMap: {[key: string]: string} = {
    [OfferType.Fixed]: "Fixed",
    [OfferType.Flexible]: "Flexible",
}