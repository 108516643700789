import React, { useState } from "react";
import MyRange from "../../../Range/Range";
import styles from "./Popup.module.css";
import Input from "../../../Input/Input";
import ModalButton from "../../../modalBtn/ModalBtn";
const Popup = ({ setPopup, offerDetails, acceptOffer, loader, steps }:any) => {
  const [borrowAmount, setBorrowAmount] = useState<any>([
    offerDetails?.loanMinValue
  ]);
  const [error, setError] = useState(false);

  const handleBorrowClick = () => {
    try {
      acceptOffer(borrowAmount[0]);
    } catch (err) {
    } finally {
    }
  };

  return (
    <>
      <section className={styles.popupWrapper}>
        <div className={`wrapper ${styles.popup}`}>
          <h2 className={styles.title}>Selected</h2>
          <p className={styles.tagline}>{borrowAmount} USDC</p>

          <div className={styles.range}>
            <>
              {error && (
                <p
                  className={styles.textAlert}
                >{`Max value allowed ${offerDetails?.loanMaxValue}`}</p>
              )}
              <Input
                onChange={(e:any) => {
                  if (+e.target.value > +offerDetails?.loanMaxValue) {
                    e.preventDefault();
                    setError(true);
                    return;
                  }

                  setError(false);
                  setBorrowAmount([e.target.value]);
                }}
                value={borrowAmount}
                type="number"
                placeholder={"Enter Amount Manually"}
              />
            </>
            <div className={styles.minMax}>
              <p className={styles.amount}>
                {offerDetails?.loanMinValue}{" "}
                <span className={styles.text}>min</span>
              </p>
              <p className={styles.amount}>
                {offerDetails?.loanMaxValue}{" "}
                <span className={styles.text}>max</span>
              </p>
            </div>
            <MyRange
              value={
                borrowAmount[0] < Number(offerDetails?.loanMinValue)
                  ? [Number(offerDetails?.loanMinValue)]
                  : borrowAmount
              }
              setValue={setBorrowAmount}
              max={offerDetails?.loanMaxValue}
              min={offerDetails?.loanMinValue}
            />
          </div>
          <div className={styles.buttonContainer}>
          

            <ModalButton
              btn1="Approve"
              btn2="Borrow"
              btn1Handler={() => handleBorrowClick()}
              steps={steps}
              loading={loader}
              disabled={
                loader ||
                borrowAmount[0] < +offerDetails?.loanMinValue ||
                borrowAmount[0] > +offerDetails?.loanMaxValue
              }
            />
          </div>
        </div>
      </section>

      <div
        className={styles.modal}
        onClick={() => {
          if (loader) {
            return;
          }
          setPopup(false);
        }}
      ></div>
    </>
  );
};

export default Popup;
