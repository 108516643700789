import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ENV } from "../../../config/config";
import { useDataContext } from "../../../context/context";
import { handleRequest, validateIfProfileIsUpdated } from "../../../utilities/common.utility";
import { getChainId, getNetwork } from "../../../utilities/web3.utility";
import Heading from "../../Heading/Heading";
import Card from "./Card/Card";
import Category from "./Category";
import Filter from "./Filter";
import MakeOfferPopup from "./MakeOfferPopup/MakeOfferPopup";
import NFTPopup from "./MarketPlaceComponents/NFTPopup/nftPopup";

const MarketPlace = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { scrollToTop, connect } = useDataContext();
  const [nfts, setNFTs] = useState<any[] | null>(null);
  const [isValidNetwork, setIsValidNetwork] = useState(false);
  const [sort, setSort] = useState<number>(3)
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [showNFTDetailPopup, setShowNFTDetailPopup] = useState(false);
  const [showMakeOfferPopup, setShowMakeOfferPopup] = useState(false);
  const [active, setActive] = useState<boolean>(true)
  const [search, setSearch] = useState<string>('')
  React.useEffect(() => {
    validateNetwork();
    if (connect && isValidNetwork) {
      getNFTs();
    } else {
      setNFTs(null);
    }
    // @ts-ignore
    window?.ethereum?.on("chainChanged", () => {
      validateNetwork();
    });
  }, [connect, isValidNetwork]);

  useEffect(() => {
    if (sort !== 0 && nfts?.length) {
      const temp: any = [...nfts]
      if (sort == 2) {
        // Sort from low to high
        temp.sort((a: any, b: any) => Number(a.maxBorowCapacity) - Number(b.maxBorowCapacity));
      } else if (sort == 1) {
        // Sort from high to low
        temp.sort((a: any, b: any) => Number(b.maxBorowCapacity) - Number(a.maxBorowCapacity));
      } else if (sort == 3) {
        // Sort based on the newest date
        temp.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      }
      setNFTs(temp)
    }
  }, [sort])

  useEffect(() => {

    if (!active) {
      const temp: any = nfts?.filter((item: any) => item.status !== "borrowed")
      setNFTs(temp)
      return
    } else {
      getNFTs()
    }
  }, [active])

  useEffect(() => {
    if (search) {
      const temp: any = nfts?.filter((item: any) => item.title.toLowerCase().startsWith(search.toLowerCase()))
      setNFTs(temp)
      return
    }
    getNFTs()

  }, [search])
  const getNFTs = async () => {
    try {
      const res = await handleRequest(
        "get",
        "mint/getItems",
        {} as any,
      );
      if (res?.data?.success) {
        let nfts = res.data.data;
        setNFTs(nfts);

        // Show Make Offer Popup after KYC, if redirected from Make Offer
        const make_offer_nft_id = searchParams.get("make_offer");
        const nft_view_nft_id = searchParams.get("nft_view");
        if (make_offer_nft_id) {
          const selected = nfts.find((nft: any) => nft._id == make_offer_nft_id);
          setSelectedNFT(selected);
          setShowMakeOfferPopup(true);
        } else if (nft_view_nft_id) {
          const selected = nfts.find((nft: any) => nft._id == nft_view_nft_id);
          setSelectedNFT(selected);
          setShowNFTDetailPopup(true);
        }
      }
    } catch (error) {
      console.log(error, "errr");
    }
  };
  const validateNetwork = async () => {
    const chainId = await getChainId();
    if (ENV.chainId !== chainId || !connect) {
      setIsValidNetwork(false);
      return false;
    } else {
      setIsValidNetwork(true);

      return true;
    }
  };

  const handleCardClick = (nft: any) => {
    setShowNFTDetailPopup(true);
    setSelectedNFT(nft);
  };

  const onCloseNFTDetailPopup = () => {
    setShowNFTDetailPopup(false);
    setSelectedNFT(null);
  };

  const handleMakeOffer = (nft: any) => {
    if (!validateIfProfileIsUpdated()) {
      toast.info("Please complete profile first");
      return navigate(`/myprofile?redirect=${nft._id}`);
    }

    setShowNFTDetailPopup(false);
    setShowMakeOfferPopup(true);
    setSelectedNFT(nft);
  }

  const onCloseMakeOfferPopup = () => {
    setShowMakeOfferPopup(false);
    setSelectedNFT(null);
  }
  return (
    <section className="pb-5">
      <div className="flex w-[90%]  gap-3 m-auto" >
        <div className="hidden md:block w-[15%] mt-60 ">
          <Category active={active} setActive={setActive} />
        </div>
        <div className="w-full sm:w-[70%]">
          <Heading
            title="Lend "
            text="Offer loans to other users on their non-fungible tokens"
            connect={isValidNetwork ?? connect}
            message={`please connect your metamask to ${getNetwork(
              ENV.chainId
            ).name} network`}
            padding
          />
          {/* <Popup />
        <NFTPopup /> */}
          <Filter setSort={setSort} setSearch={setSearch} />


          <Row className="gy-3 gx-3">
            {nfts &&
              // @ts-ignore
              nfts?.length > 0 &&
              connect &&
              // @ts-ignore
              nfts?.map((el: any, i: number) => {
                return (
                  <Col xs={12} md={6} lg={3} key={i}>
                    <Card
                      image={el.fileUrl}
                      price={el.maxBorowCapacity}
                      cardTitle={el?.title}
                      id={el._id}
                      connect={connect}
                      scrollTotop={scrollToTop}
                      data={el}
                      ownerAddress={el?.ownerAddress?.toUpperCase()}
                      onClick={() => handleCardClick(el)}
                      onMakeOffer={() => handleMakeOffer(el)}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>

      </div>
      {showNFTDetailPopup && selectedNFT && (
        <NFTPopup
          el={selectedNFT}
          connect={connect}
          scrollToTop={scrollToTop}
          onClose={onCloseNFTDetailPopup}
          onMakeOffer={() => handleMakeOffer(selectedNFT)}
        />
      )}
      {showMakeOfferPopup && selectedNFT && (
        <MakeOfferPopup
          onClose={onCloseMakeOfferPopup}
          mintDetails={selectedNFT}
        />
      )}
    </section>
  );
};

export default MarketPlace;
