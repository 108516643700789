import {
  getContractMethods,
  getGasPriceFromStation,
  getWeb3,
} from "../../web3.utility";
import { CONTRACT_DETAILS } from "../contractsDetails";

const fixedLoanOffer = async (
  durations :any,
  enteryFee :any,
  apr :any,
  minLoan :any,
  maxLoan :any,
  nftId :any,
  nftContract :any,
  erc20Contract :any,
  expiration :any,
  borrowerAddress :any
) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);

    const minLoanWei = web3.utils.toWei(minLoan,'mwei');

    const maxLoanWei = web3.utils.toWei(maxLoan,'mwei');

    const estimatedGasFees = await contract.methods
      .fixedLoanOffer(
        durations,
        apr,
        web3.utils.toBN(minLoanWei as any),
        web3.utils.toBN(maxLoanWei as any),
        nftId,
        borrowerAddress
      )
      .estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    const responsFixedLoanOffer = await contract.methods
      .fixedLoanOffer(
        durations,
        apr,
        web3.utils.toBN(minLoanWei as any),
        web3.utils.toBN(maxLoanWei as any),
        nftId,
        borrowerAddress
      )
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation,
      });
    return responsFixedLoanOffer;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ err",
      err
    );
  }
};

// borrowLoan

const borrowLoan = async (nftId :any, offerId :any, amount:any, type:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);

    const amountWei = web3.utils.toWei(amount,"mwei");

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    let responsFixedLoanOffer = null;
    if (type === "fixed") {

      const estimatedGasFees = await contract.methods
        .borrowFixLoan(nftId, offerId, amountWei)
        .estimateGas({ from: accounts[0] });

      responsFixedLoanOffer = await contract.methods
        .borrowFixLoan(nftId, offerId, amountWei)
        .send({
          from: accounts[0],
          gas: estimatedGasFees,
          gasPrice: gasPriceFromStation,
        });
    } else {

      const estimatedGasFees = await contract.methods
        .borrowFlexibleLoan(nftId, offerId, amountWei)
        .estimateGas({ from: accounts[0] });

      responsFixedLoanOffer = await contract.methods
        .borrowFlexibleLoan(nftId, offerId, amountWei)
        .send({
          from: accounts[0],
          gas: estimatedGasFees,
          gasPrice: gasPriceFromStation,
        });
    }
    return responsFixedLoanOffer;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ borrowLoan ~ err",
      err
    );
  }
};

const flexibledLoanOffer = async ({
  duration,
  apr,
  minLoan,
  maxLoan,
  nftId,
  acceptableDebt,
  borrowerAddress,
}:any) => {
  
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);

    const minLoanWei = web3.utils.toWei(`${minLoan}`, "mwei");
    const maxLoanWei = web3.utils.toWei(`${maxLoan}`, "mwei");

    const estimatedGasFees = await contract.methods
      .flexibleLoanOffer(
        duration,
        apr,
        web3.utils.toBN(minLoanWei),
        web3.utils.toBN(maxLoanWei),
        nftId,
        +acceptableDebt,
        borrowerAddress
      ).estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    const responsFixedLoanOffer = await contract.methods
      .flexibleLoanOffer(
        duration,
        apr,
        web3.utils.toBN(minLoanWei),
        web3.utils.toBN(maxLoanWei),
        nftId,
        +acceptableDebt,
        // nftContract,
        // erc20Contract,
        // expiration,
        borrowerAddress
        // accounts[0]
      )
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation,
      });
    return responsFixedLoanOffer;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ line 144 ~ err",
      err
    );
  }
};

const repayLoan = async (nftId:any, offerType:any, _selectAmount:any, amountToRepay:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);
    let amountWei = null;
    if (!amountToRepay) {
      amountWei = web3.utils.toWei(_selectAmount,"mwei");
    }

    const estimatedGasFees = await contract.methods
      .repayLoan(
        nftId,
        offerType,
        web3.utils.toBN(amountToRepay ? _selectAmount : amountWei)
      )
      .estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    const responsFixedLoanOffer = await contract.methods
      .repayLoan(
        nftId,
        offerType,
        web3.utils.toBN(amountToRepay ? _selectAmount : amountWei)
      )
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation,
      });
    return responsFixedLoanOffer;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ repayLoan ~ err",
      err
    );
  }
};

const whitelistBorrower = async (walletAddress:any, whiteList:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;
    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);
    
    const estimatedGasFees = await contract.methods
      .whitelistBorrower(walletAddress, whiteList)
      .estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    const responsFixedLoanOffer = await contract.methods
      .whitelistBorrower(walletAddress, whiteList)
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation,
      });
    return responsFixedLoanOffer;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ line 210 ~ repayLoan ~ err",
      err
    );
  }
};

const fixedBorrow = async (nftId:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;
    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts();
    const responseFixedBorrow = await contract.methods
      .borrow(nftId)
      .call({ 
        from: accounts[0]
      });

    return responseFixedBorrow;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ line 285 ~ fixedBorrow ~ err",
      err
    );
  }
};

const readDynamicInterest = async (nftId:any, offerId:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;
    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts();
    const responseFixedBorrow = await contract.methods
      .readDynamicInterest(nftId, offerId)
      .call({
        from: accounts[0]
      });
    return responseFixedBorrow;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ fixedBorrow ~ err",
      err
    );
  }
};
const payableAmountForBorrower = async (nftId:any, offerId:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;
    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts();
    const responseFixedBorrow = await contract.methods
      .payableAmountForOwna(+nftId, +offerId)
      .call({
        from: accounts[0]
      });
    return responseFixedBorrow;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ fixedBorrow ~ err",
      err
    );
  }
};

const payableAmountByBorrower = async (nftId:any, offerId:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );

    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts();

    const responseFixedBorrow = await contract.methods
      .payableAmountForBorrower(+nftId, +offerId)
      .call({
        from: accounts[0]
      });
    return responseFixedBorrow;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ fixedBorrow ~ err",
      err
    );
  }
};

const flexibleBorrow = async (offerId:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;
    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts();

    const responseFlexibleBorrow = await contract.methods
    .borrow(offerId)
      .call({
        from: accounts[0]
      });

    return responseFlexibleBorrow;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaLendingAndBorrowingContractFunctions.js ~ fixedBorrow ~ err",
      err
    );
  }
};

const repayLoanByAdmin = async (nftId:any, offerId:any) => {
  try {

    const contractDetails = CONTRACT_DETAILS.borrowAndLending;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);

    const estimatedGasFees = await contract.methods
      .ownaPay(+nftId, +offerId)
      .estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    const res = await contract.methods
      .ownaPay(+nftId, +offerId)
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation
      });

    return res;
  } catch (err:any) {
    console.log(err, "error");
    throw new Error(err);
  }
};

const sellAssetToOwna = async (nftId:any, offerId:any) => {
  try {

    const contractDetails = CONTRACT_DETAILS.borrowAndLending;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);
    const estimatedGasFees = await contract.methods
      .sellAssetToOwna(+nftId, +offerId)
      .estimateGas({ from: accounts[0] });
    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    const res = await contract.methods
      .sellAssetToOwna(+nftId, +offerId)
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation
      });

    return res;
  } catch (err:any) {
    console.log(err, "error");
    throw new Error(err);
  }
};

const claimFundsByLender = async (nftId:any, offerId:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);

    const estimatedGasFees = await contract.methods
      .lendersFunds(+nftId, offerId)
      .estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);
    const res = await contract.methods
      .lendersFunds(+nftId, offerId)
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation
      });
    return res;
  } catch (err:any) {
    throw new Error(err);
  }
};
const cancelOffer = async (nftId:any, offerId:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.borrowAndLending;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);

    const estimatedGasFees = await contract.methods
      .cancelOffer(+nftId, offerId)
      .estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);
    const res = await contract.methods
      .cancelOffer(+nftId, offerId)
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation
      });
    return res;
  } catch (err:any) {
    throw new Error(err);
  }
};

export {
  fixedLoanOffer,
  borrowLoan,
  flexibledLoanOffer,
  repayLoan,
  whitelistBorrower,
  fixedBorrow,
  flexibleBorrow,
  repayLoanByAdmin,
  claimFundsByLender,
  readDynamicInterest,
  payableAmountForBorrower,
  payableAmountByBorrower,
  sellAssetToOwna,
  cancelOffer
};
