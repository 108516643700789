import React from "react";
import {Stepper, Step, StepLabel} from "@mui/material";
import styles from "../modalBtn/styles.module.css";

const stepperOptions = ["", " "];

interface ModalBtnProps {
    btn1: string;
    btn2: string;
    disabled: boolean;
    loading: boolean;
    steps: StepsProps;
    btn1Handler?: () => void;
}

interface StepsProps {
    step1: boolean;
    step2: boolean;
}

const ModalBtn = ({btn1, btn2, disabled, loading, steps, btn1Handler}: ModalBtnProps) => {
    const {step1, step2} = steps;

    return (
        <>
            <div className={styles.mainModalBtn}>
                <div>
                    <Stepper activeStep={step2 ? 1 : -1}>
                        {stepperOptions.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {(
                        <div className={styles.btns}>
                            <button
                                type="submit"
                                onClick={() => btn1Handler && btn1Handler()}
                                disabled={disabled || step2}
                                className={step2 || loading ? styles.disableBtn : styles.active}
                            >
                                {step1 && loading ? "loading" : btn1}
                            </button>
                            <button
                                type="submit"
                                disabled={disabled || step1 || step2}
                                className={step1 ? styles.disableBtn : styles.active}
                            >
                                {step2 && loading ? "loading" : btn2}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ModalBtn;
