import { Box, Checkbox, FormControlLabel } from '@mui/material';
import * as React from 'react';

export default function CategoryCheckbox() {
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', borderTop: '1px solid grey', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="watches"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="Watches"
                    labelPlacement="end"
                />
            </Box>
            <Box sx={{ width: '100%', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="Sneakers"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="Sneakers"
                    labelPlacement="end"
                />
            </Box>
            <Box sx={{ width: '100%', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="watches"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="Watches"
                    labelPlacement="end"
                />
            </Box>
            <Box sx={{ width: '100%', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="Pens"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="Pens"
                    labelPlacement="end"
                />
            </Box>
            <Box sx={{ width: '100%', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="Art"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="Art"
                    labelPlacement="end"
                />
            </Box>
            <Box sx={{ width: '100%', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="Cars"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="Cars"
                    labelPlacement="end"
                />
            </Box>
            <Box sx={{ width: '100%', borderBottom: '1px solid grey', py: '5px', px: '10px' }}>
                <FormControlLabel
                    value="Collectibles"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    control={<Checkbox size='small' />}
                    label="Collectibles"
                    labelPlacement="end"
                />
            </Box>
        </Box>
    )
}
