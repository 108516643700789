import {OfferBorrowStatus, OfferStatus, OfferStateLabelMap, OfferType, OfferTypeLabelMap} from "./types";

// Get the NFT status label showing on pages from nft status and nft borrowing status
export function getNftStateLabel(state: OfferStatus, borrowState: OfferBorrowStatus | undefined) {
    if (state == OfferStatus.Accepted && borrowState == OfferBorrowStatus.Borrowed) {
        return "Active";
    }

    if (borrowState == OfferBorrowStatus.SellIt) {
        return "Sell-It";
    }

    return OfferStateLabelMap[state];
}

// Get the label of special offer type
export function getOfferTypeLabel(type: OfferType) {
    return OfferTypeLabelMap[type];
}

export function getOfferStateLabel(status: OfferStatus) {
    return OfferStateLabelMap[status];
}