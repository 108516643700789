import React, { useEffect, useState } from "react";
import P2pComponents from "./P2pComponents/P2pComponents";
import styles from "./P2poffer.module.css";
import { handleRequest } from "../../../utilities/common.utility";
import moment from "moment";
import { useDataContext } from "../../../context/context";
import { getChainId } from "../../../utilities/web3.utility";
import { ENV } from "../../../config/config";
import {getOfferTypeLabel} from "../../../utilities/onboard";

const P2poffer = ({ setP2Poffers, setHidePlaceHolder }:any) => {
  const [offers, setOffers] = useState<any>(null);
  const { connect }:any = useDataContext();
  const [isValidNetwork, setIsValidNetwork] = useState(false);

  useEffect(() => {
    setP2Poffers(offers);
  }, [setP2Poffers, offers]);

  useEffect(() => {
    validateNetwork();
    if (connect && isValidNetwork) {
      getOffers();
    } else {
      setOffers([]);
    }
    // @ts-ignore
    window?.ethereum?.on("chainChanged", () => {
      validateNetwork();
    });
  }, [connect, isValidNetwork]);

  const validateNetwork = async () => {
    const chainId = await getChainId();
    if (ENV.chainId !== chainId || !connect) {
      setIsValidNetwork(false);
      return false;
    } else {
      setIsValidNetwork(true);

      return true;
    }
  };

  const getOffers = async () => {
    const res = await handleRequest(
      "get",
      `offer/getOffersByStatus?status=pending&&address=${connect}`
    );
    const { offers } = res.data;

    let data = offers.map((item:any) => {
    
      let info = [
        {
          title: "Lender ",
          text:
            item?.fromAddress?.slice(0, 4) +
            "..." +
            item?.fromAddress?.slice(-4),
          copy: item?.fromAddress
        },
      
        {
          title: "Received ",
          text: moment(item?.createdAt).format("DD MMM hh:mm")
        },

        {
          title: "Duration ",
          text: `${item?.period || ""} d`
        },
        {
          title: "Type",
          text: `${getOfferTypeLabel(item?.type) ?? ""}`
        },
        { title: "APR ", text: item?.apr },

        { title: "Loan Min value  ", text: `${item?.loanMinValue} USDC ` },
        { title: "Loan Max value  ", text: `${item?.loanMaxValue} USDC ` }
      ];

      if (item?.type === "flexible") {
        info = [
          ...info,
          {
            title: "Condition ",
            text: `Total debt must remain under ${
              item?.maximumacceptabledebt + 2
            }% APR `
          }
        ];
      }

      return {
        offerDetails: item,
        info
      };
    });
    setOffers(data);
    if (offers?.length > 0) {
      setHidePlaceHolder(true);
    }
  };
  return (
    <>
      <section className={` ${styles.p2pOffer}`}>
        {offers &&
          offers.length > 0 &&
          connect &&
          offers.map((el:any, i:number) => (
            <P2pComponents data={el} key={i} getOffers={getOffers} />
          ))}
      </section>
    </>
  );
};

export default P2poffer;
