import { useState, useContext, createContext,ReactNode } from "react";
import React from 'react';

type ContextProps = {
    estimateAmount: null | number;
    setEstimateAmount: (value: null | number) => void;
    backOffice: boolean;
    setBackOffice: (value: boolean) => void;
    connect: string;
    setConnect: (value: string) => void;
    refetch: boolean;
    setRefetch: (value: boolean) => void;
    scrollToTop: () => void;
};

export const StateData = createContext<ContextProps>({
    estimateAmount: null,
    setEstimateAmount: () => {},
    backOffice: true,
    setBackOffice: () => {},
    connect: "",
    setConnect: () => {},
    refetch: false,
    setRefetch: () => {},
    scrollToTop: () => {},
});

type Props = {
    children: ReactNode;
};

export const ContextProvider = ({ children }: Props) => {
    const [estimateAmount, setEstimateAmount] = useState<null | number>(null);
    const [backOffice, setBackOffice] = useState<boolean>(true);
    const [connect, setConnect] = useState<string>("");
    const [refetch, setRefetch] = useState<boolean>(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <StateData.Provider
            value={{
                estimateAmount,
                setEstimateAmount,
                backOffice,
                setBackOffice,
                scrollToTop,
                connect,
                setConnect,
                refetch,
                setRefetch,
            }}
        >
            {children}
        </StateData.Provider>
    );
};

export const useDataContext = (): ContextProps => useContext(StateData);
