import React, { useEffect, useState } from "react";
import { useDataContext } from "../../../../context/context";
import { handleRequest } from "../../../../utilities/common.utility";
import styles from "./MyBorrowItems.module.css";

interface MyBorrowItemsProps {
    isValidNetwork: boolean;
    setHidePlaceHolder: (placeholder: boolean) => void;
}

const MyBorrowItems = ({isValidNetwork, setHidePlaceHolder}: MyBorrowItemsProps) => {
    const [borrowItems, setBorrowItems] = useState<Array<any>>([]);
    const { connect } = useDataContext();
    useEffect(() => {
        if (connect && isValidNetwork) {
            getMyBorrowItems();
        }
    }, [connect, isValidNetwork]);

    const getMyBorrowItems = async () => {
        try {
            const res = await handleRequest(
                "get",
                "mint/getItems",
                {} as any,
                // @ts-ignore
                {walletAddress: connect, status: "lending"}
            );

            if (res?.data?.success) {
                let nfts = res.data.data;
                setBorrowItems(nfts);
                if (nfts.length > 0) {
                    setHidePlaceHolder(true);
                }
            }
        } catch (error) {
            console.log(error, "errr");
        }
    }

    if (!borrowItems) {
        return null;
    }

    return (
        <div className="w-full">
            {borrowItems.map((item, index) => (
                <div className={`${styles.wrapper} ${styles.wrapperBorder}`}>
                    <div className={styles.dataWrapper}>
                        <div className={styles.image}>
                            <img src={item.fileUrl} alt="#" className={styles.icon} />
                        </div>
                        <div className={styles.titleAndIcon}>
                            <div>
                                <p className={styles.title}>Status</p>
                                <p className={styles.text}>Activated</p>
                            </div>
                        </div>
                        <div className={styles.caption}>
                            <p>Your asset is currently on the marketplace waiting for offers</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default MyBorrowItems;