import cx from "classnames";
import styles from "./List.module.css";
import React from "react";

export const List = ({ items, className }:any) => {
  return (
    <ul className={cx(styles.list, className)}>
      {items.map((item:any, index:number) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};
