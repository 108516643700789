import React from "react";
import styles from "./Input.module.css";
import { IconButton, Tooltip } from "@mui/material";
import { MdHelp } from "react-icons/md";

export interface IInputProps {
    label?: string;
    type?: string;
    name?: string;
    background?: string;
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string | number;
    defaultValue?: string | number;
    disable?: boolean;
    minLength?: number;
    required?: boolean;
    msgs?: {
        show: boolean;
        name: string;
        error: boolean;
        msg: string;
        apr?: number;
    };
    min?: number;
    maxLength?: number;
    max?: number;
    input?: IInputProps
    tooltip?: string;
}

const Input = (
    {
        label,
        type,
        name,
        background,
        placeholder,
        onChange,
        value,
        disable = false,
        minLength,
        required,
        msgs,
        min,
        maxLength,
        max,
        tooltip,
    }: IInputProps) => {
    return (
        <div className={styles.inputContainer}>
            <div className={styles.labelWrapper}>
                <label htmlFor={name} className={`${styles.label} ${styles.text}`}>
                    {label}{tooltip && <Tooltip title={tooltip} placement="top"><IconButton size="small"><MdHelp /></IconButton></Tooltip>}
                </label>
                {msgs && msgs?.show && msgs?.name === name && (
                    <p
                        className={
                            msgs?.name === name && msgs?.error === true
                                ? styles.textError
                                : ""
                        }
                    >
                        {msgs?.msg}
                    </p>
                )}
            </div>
            <input
                type={type}
                id={name}
                minLength={minLength && minLength}
                max={msgs?.name === name && msgs?.apr !== undefined ? +msgs.apr : max}
                maxLength={maxLength && maxLength}
                min={min && min}
                name={name}
                style={{
                    background: background ? background : "",
                }}
                value={value}
                required={required}
                className={`${styles.input} ${styles.text}`}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disable === true}
                onWheel={(event) => event.currentTarget.blur()}
            />
        </div>
    );
};

export default Input;
