import React, {ChangeEvent} from "react";

import styles from "./UploadingPhoto.module.css";
import {useState} from "react";

interface Props {
    onChange: (name: string, e: ChangeEvent<HTMLInputElement>) => void;
    values: Record<string, any>;
    prevAppointment?: string | null;
}

const UploadingPhoto = ({onChange, values, prevAppointment}: Props) => {
    const photoData = [
        {title: "Photo 1: Facing*", name: "facing", img: ""},
        {title: "Photo 2: Back*", name: "back", img: ""},

        {title: "Photo 3: Left: Facing*", name: "leftF", img: ""},

        {title: "Photo 4: Right: Facing*", name: "rightF", img: ""},
    ];

    return (
        <>
            <div className={styles.container}>
                <label className={styles.label}>Origin*</label>
                <div className={styles.fileName}>
                    <label htmlFor="origin" className={styles.button}>
                        Upload
                        <input
                            type="file"
                            id="origin"
                            name="origin"
                            onChange={(e) => onChange("origin", e)}
                            className={styles.input}
                        />
                    </label>
                    {values.origin && values.origin.name && (
                        <p className={styles.filetext}>{values.origin?.name}</p>
                    )}
                </div>
            </div>

            {photoData.map((el, i) => (
                <div key={i} className={styles.container}>
                    <label className={styles.label}>{el.title}</label>
                    <div className={styles.fileName}>

                        <label htmlFor={el.name} className={styles.button}>
                            Upload
                            <input
                                type="file"
                                id={el.name}
                                name={el.name}
                                onChange={(e) => onChange(el.name, e)}
                                className={styles.input}
                            />
                        </label>
                        {values[el.name] && (
                            <p className={styles.filetext}>{values[el.name].name}</p>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

export default UploadingPhoto;

