import React from "react";
import styles from "./Stake.module.css";
// @ts-ignore
import {ReactComponent as UsdcIcon} from "../../../images/usdc.svg";
// @ts-ignore
import onaIcon from "../../../images/ona.svg";
import StakeComponents from "./StakeComponents/StakeComponents";
import {useState} from "react";
import Heading from "../../Heading/Heading";
import Button from "../../Button/Button";
import StakeModal from "./StakeComponents/StakeModal/StakeModal";
import UnStakeModal from "./Modal/UnStakeModal";
import ClaimModal from "./Modal/ClaimModal";

const Stake = () => {
    const [usdModal, setUsdModal] = useState();
    const [onadModal, setOnaModal] = useState<boolean>();
    const [unstakeModal, setUnstakeModal] = useState(false);
    const [claimModal, setClaimModal] = useState(false);
    const data = [
        {
            percentage: "11.00%",
            getButton: {
                text: "Get USDC Token",
            },
            img: <UsdcIcon/>,
            currency: "USDC",
            stake: false,
            amount: 0,
            amountUSD: 0,
            button: {text: " UnLock USDC"},
            dayTitle: "Cooldown period",
            day: 10,
            myModal: <UnStakeModal setPopup={setUnstakeModal} img={<UsdcIcon/>}/>,
            modal: unstakeModal,
            setModal: setUnstakeModal,
        },
        {
            percentage: "11.00%",
            getButton: {
                text: "Get USDC ONA",
            },
            img: onaIcon,
            currency: "USDC",
            claim: true,
            amount: 0,
            amountUSD: 0,
            button: {text: " Widthraw"},
            dayTitle: "USDC per month",
            usdc: "0",
            myModal: <ClaimModal setPopup={setClaimModal} img={<UsdcIcon/>}/>,
            modal: claimModal,
            setModal: setClaimModal,
        },
    ];
    return (
        <section className={styles.stake}>
            <div className={styles.overlay}></div>
            <div className="container">
                <div className={styles.stakeWrapper}>
                    <Heading title="Stake - coming soon" text="Deposit liquidity in Owna's Pool to earn interests"/>
                    <div className={styles.usdcContainer}>
                        <div className={styles.usdc}>
                            <UsdcIcon/>
                            <p className={styles.currency}>USDC</p>
                        </div>
                        <div className={styles.appromaxContainer}>
                            <p className={styles.appromax}>Staking APR</p>
                            <span className={styles.percentage}>6.72%</span>
                        </div>
                        <div className={styles.appromaxContainer}>
                            <p className={styles.appromax}>Max slashing</p>
                            <span className={styles.percentage}>30.00%</span>
                        </div>
                        {" "}
                        <div className={styles.appromaxContainer}>
                            <p className={styles.appromax}>Wallet Balance</p>
                            <span className={styles.percentage}>0</span>
                        </div>
                        <div className={styles.appromaxContainer}>
                            {" "}
                            <Button click={() => setOnaModal(true)}>Lock</Button>
                        </div>
                    </div>
                    <div className={styles.componentswrapper}>
                        {data.map((el, i) => (
                            <StakeComponents {...el} key={i}/>
                        ))}
                        {onadModal && <StakeModal setPopup={setOnaModal} currency={"USDC"} img={<UsdcIcon/>}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Stake;
