import LendingDashboardItem from "../LendingDahboardComponent";
import {
    handleRequest,
    percentage,
    isWhatPercentOf
} from "../../../../utilities/common.utility";
import RefundPopup from "../RefundPopup/RefundPopup";
import moment from "moment";
import { getWeb3 } from "../../../../utilities/web3.utility";
import borrowedPlaceHolder from 'images/dashboard_1.svg'
import React, { useState, useEffect } from "react";
import SellItemModal from "../SellItemModal/SellItemModal";
import { toast } from "react-toastify";
import { useDataContext } from "../../../../context/context";
import styles from "../Dashboard.module.css";
import {
    fixedBorrow,
    readDynamicInterest,
    flexibleBorrow,
    payableAmountByBorrower,
    sellAssetToOwna
} from "../../../../utilities/Contracts/OwnaLendingAndBorrowing/ownaLendingAndBorrowingContractFunctions";

import { useNavigate } from "react-router-dom";
import { getOfferTypeLabel } from "../../../../utilities/onboard";
import { OfferBorrowStatus, OfferStatus } from "../../../../utilities/types";
import MyBorrowItems from "../MyBorrowItems/MyBorrowItems";


interface Props {
    admin?: boolean;
    getNFTs?: React.Dispatch<React.SetStateAction<boolean>>;
    lender?: boolean;
    isValidNetwork?: boolean;
    bringAsset: () => void;
    onGetAssetBack: () => void;
    p2poffers?: any;
    setHidePlaceHolder: (placeholder: boolean) => void;
    hidePlaceholder?: boolean;
}

const BorrowedItemsDashboard = (
    {
        admin,
        getNFTs,
        lender,
        isValidNetwork,
        bringAsset,
        onGetAssetBack,
        p2poffers,
        setHidePlaceHolder,
        hidePlaceholder
    }: Props) => {

    const [refundpopup, setRefundPopup] = useState(false);
    const [sellitpopup, setSellitPopup] = useState(false);
    const [borrowedItems, setBorrowedItems] = useState<any>(null);
    const [sellItData, setSellItData] = useState<any>(null);
    const [refundData, setRefundData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const {connect, scrollToTop, refetch}: any = useDataContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (connect && isValidNetwork) {
            getBorrowedItems();
        } else {
            setBorrowedItems([]);
        }
    }, [connect, isValidNetwork, refetch]);

    const handleSellItem = async () => {
        try {
            const responseUpdateBorrow = await handleRequest(
                "post",
                "borrow/updateOnlyBorrowStatus",
                {},
                {
                    borrowId: sellItData._id,
                    status: "sellIt",
                    address: sellItData?.mintId?.fromAddress
                }
            );

            if (responseUpdateBorrow?.data?.success) {
                setSellitPopup(false);
                getBorrowedItems();
                toast.success("Item transfered to owna successfully");
                return;
            }
        } catch (err) {
            console.log(
                "🚀 ~ file: Dashboard.ts ~ line 185 ~ handleSellItem ~ err",
                err
            );
        } finally {
            setLoading(false);
        }
    };

    const sellAssetToOwnaFromContract = async () => {
        try {
            setLoading(true);
            let offerId = undefined;
            if (sellItData?.offerId.type === "fixed") {
                offerId = sellItData.offerId.fixedOfferId;
            } else {
                offerId = sellItData.offerId.flexibleOfferId;
            }
            await sellAssetToOwna(sellItData?.mintId?.tokenId, offerId);
            await handleSellItem();
        } catch (err: any) {
            setLoading(false);
            toast.error(err);
        }
    };

    const getBorrowedItems = async () => {
        try {
            const res = await handleRequest(
                "get",
                `borrow/getBorrowedItems`,
                {} as any,
                {
                    address: connect,
                    status: "borrowed",
                    ...(lender && {lender: lender ? connect : false})
                }
            );
            if (res?.data?.success) {
                const {data} = res?.data;

                if (data) {
                    let updatedData = await Promise.all(
                        data?.map(async (item: any) => {
                            const web3 = await getWeb3();
                            let contractSyncBorrowInfo = undefined;
                            let contractInterest: any = undefined;
                            let amountOfBorrower = undefined;
                            if (item?.offerId?.type === "fixed") {
                                contractSyncBorrowInfo = await fixedBorrow(
                                    item?.mintId?.tokenId
                                );
                            } else {
                                contractSyncBorrowInfo = await flexibleBorrow(
                                    item?.mintId?.tokenId
                                );
                            }
                            contractInterest = await readDynamicInterest(
                                item?.mintId?.tokenId,
                                item.offerId.type === "fixed"
                                    ? item.offerId.fixedOfferId
                                    : item.offerId.flexibleOfferId
                            );

                            amountOfBorrower = await payableAmountByBorrower(
                                item?.mintId?.tokenId,
                                item.offerId.type === "fixed"
                                    ? item.offerId.fixedOfferId
                                    : item.offerId.flexibleOfferId
                            );

                            contractInterest = web3.utils.fromWei(
                                contractInterest || "0",
                                "mwei"
                            );
                            let contractOutStandingLoan = web3.utils.fromWei(
                                contractSyncBorrowInfo.loanAmount || "0",
                                "mwei"
                            ) as any;

                            contractSyncBorrowInfo = web3.utils.fromWei(
                                amountOfBorrower || "0",
                                "mwei"
                            );

                            var a = moment(item.createdAt);
                            const totalDaysToRefund = moment();
                            const dateInDays = totalDaysToRefund.diff(a, "days");
                            const daysLeft = Number(item.offerId.period) - dateInDays;

                            contractOutStandingLoan =
                                Number(contractOutStandingLoan) + Number(contractInterest);
                            const loanAmount = contractOutStandingLoan - contractInterest;
                            const currentInterestPercantage = isWhatPercentOf(
                                contractInterest,
                                loanAmount
                            );

                            const isBorrowing = item.status == OfferStatus.Accepted && item.borrow?.status == OfferBorrowStatus.Borrowed && item.borrow.lenderAddress == connect;

                            // Non-Borrowing Type - Duration - Outstanding loan / Volume - APR - Received - Lender
                            // Borrowing Type - Outstanding loan / Volume - Cumulated interests - Daily interest - Received - Lender
                            let info = [
                                {
                                    data: {
                                        ...item,
                                        contractSyncBorrowInfo,
                                        contractInterest,
                                        contractOutStandingLoan,
                                        amountOfBorrower
                                    },
                                    copy: false
                                },
                                {
                                    img: item.mintId.fileUrl
                                },
                                {title: "Type ", text: getOfferTypeLabel(item.offerId.type)},
                            ];

                            if (!isBorrowing) {
                                info.push({title: "Duration ", text: item.offerId.period + "d"});
                            }

                            info = info.concat([
                                {
                                    title: "Outstanding loan",
                                    text: Number(contractOutStandingLoan).toFixed(2) + " USDC"
                                },
                                {
                                    title: "Volume",
                                    text: `${item.offerId.loanMinValue} - ${item.offerId.loanMaxValue} USDC `
                                }]);

                            if (!isBorrowing) {
                                info.push({title: "APR ", text: item.offerId.apr + "%"});
                            } else {
                                info.push(
                                    {
                                        title: "Cumulated interests ",
                                        text: `${
                                            Number(contractInterest).toFixed(2) || 0
                                        } USDC (${currentInterestPercantage.toFixed(0)}%) `
                                    }
                                );
                            }

                            info = info.concat([
                                {
                                    title: "Daily interest",
                                    text: `${(item.dailyInterest || 0)?.toFixed(5)} USDC`
                                },
                                {
                                    title: "Received",
                                    text: moment(item.createdAt).format("DD MMM hh:mm")
                                },
                                {
                                    title: "Lender",
                                    text: `${
                                        item.offerId?.fromAddress.slice(0, 5) +
                                        ".." +
                                        item.offerId?.fromAddress.slice(-5)
                                    }`,
                                }]);

                            if (item.offerId.type === "fixed") {
                                info.push({
                                    title: "Remaining days",
                                    text: `${daysLeft}d`
                                });
                            }

                            return {info};
                        }) //loop
                    );
                    setBorrowedItems(updatedData);
                    if (data?.length > 0) {
                        setHidePlaceHolder(true);
                    }
                }
            }
        } catch (err) {
            console.log(
                "🚀 ~ file: Dashboard.ts ~ line 42 ~ getBorrowedItems ~ err",
                err
            );
        }
    };

    const hasFullReimbursedOffer = borrowedItems && !!(borrowedItems.find((item: any) => item.status === OfferStatus.Accepted && item.borrow?.status === OfferBorrowStatus.Refunded))

    return (
        <>
            {refundpopup && refundData && (
                <RefundPopup
                    setPopup={setRefundPopup}
                    getBorrowedItems={getBorrowedItems}
                    connect={connect}
                    borrowContractDetails={{
                        totalRepayDebt: Number(refundData?.contractOutStandingLoan),
                        amountOfBorrower: refundData.amountOfBorrower
                    }}
                    min={
                        refundData?.refundedAmount <= 0
                            ? percentage(refundData.amount, 2)
                            : 0
                    }
                    offerDetails={{
                        ...refundData.offerId,
                        borrowId: refundData._id,
                        loanMaxValue: 0 || refundData.contractOutStandingLoan,
                        nftId: refundData.mintId.tokenId,
                        interest: refundData.interest,
                        contractInterest: refundData.contractInterest,
                        dailyInterest: refundData.dailyInterest,
                        amount: refundData.amount,
                        maximumacceptabledebt: refundData.offerId.maximumacceptabledebt
                    }}
                />
            )}
            {sellitpopup && (
                <SellItemModal
                    setPopup={setSellitPopup}
                    handleSellItem={sellAssetToOwnaFromContract}
                    loading={loading}
                />
            )}
            <div className={`${styles.dashboard2} `}>
                {hidePlaceholder && (
                    <div className={`${styles.tabBtn} my-5 w-100`}>
                        <button onClick={() => bringAsset()}>Bring Asset</button>
                        {hasFullReimbursedOffer &&
                            <button className="ml-6" onClick={onGetAssetBack}>Get Asset Back</button>}
                    </div>
                )}
                {!hidePlaceholder && (
                    <>
                        <div className={styles.imgContainer}>
                            <img
                                className={styles.placeHolderimg}
                                src={borrowedPlaceHolder}
                                alt=""
                            />
                        </div>
                    </>
                )}

                <MyBorrowItems isValidNetwork={!!isValidNetwork} setHidePlaceHolder={setHidePlaceHolder} />

                {borrowedItems &&
                    borrowedItems?.length > 0 &&
                    borrowedItems.map((el: any, i: number) => {
                        return (
                            <LendingDashboardItem
                                data={el.info}
                                key={i}
                                loading={loading}
                                borrow={true}
                                connect={connect}
                                handleSellItem={(data: any) => {
                                    scrollToTop();
                                    setSellItData(data);
                                    setSellitPopup(true);
                                }}
                                handleRefundItem={(data: any) => {
                                    scrollToTop();
                                    setRefundData(data);
                                    setRefundPopup(true);
                                }}
                            />
                        );
                    })}
                {!hidePlaceholder && (
                    <div className={`${styles.tabBtn} my-5`}>
                        <button onClick={bringAsset}>Bring Asset</button>
                    </div>
                )}
            </div>
        </>
    );
};
export default BorrowedItemsDashboard;
