import { Navigate } from "react-router-dom";
import React, { FC } from "react";

type PrivateRouteProps = {
    access: boolean;
    children: React.ReactNode;
};

const PrivateRoute: FC<PrivateRouteProps> = ({ access, children }) => {
    return access ? <>{children}</> : <Navigate to="/"/>;
};

export default PrivateRoute;
