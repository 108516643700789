import React from "react";

import Button from "../../../../Button/Button";
import styles from "./StakeModal.module.css";
// @ts-ignore
import { ReactComponent as UsdtIcon } from "../../../../../images/usdt.svg";

const StakeModal = ({ setPopup, currency, img }:any) => {
  return (
    <>
      <section className={styles.popupWrapper}>
        <div className={`wrapper ${styles.popup}`}>
          <div className={styles.main}>
            <fieldset className={styles.fieldset}>
              <legend className={styles.legend}>
                Amount of {currency} to convert and stake
              </legend>
              <input type="text" className={styles.input} />
              <div className={styles.buttonImage}>
                <button className={styles.maxButton}>max</button>
                {/* <img src={img} alt="#" className={styles.maxImage} /> */}
                <UsdtIcon />
              </div>
            </fieldset>
            <fieldset className={styles.fieldset}>
              <legend className={styles.legend}>Lock Time in days</legend>
              <input type="number" className={styles.input} />
              {/* <div className={styles.buttonImage}>
                <button className={styles.maxButton}>max</button>
                <img src={img} alt="#" className={styles.maxImage} />
              </div> */}
            </fieldset>
            <div className={styles.durationBtnContainer}>
              <Button
                btnStyles={{
                  padding: "10px 20px",
                  minWidth: "111px",
                  marginRight: "7px",
                }}
              >
                1 Month
              </Button>
              <Button
                btnStyles={{
                  padding: "10px 20px",
                  minWidth: "111px",
                  marginRight: "7px",
                }}
              >
                3 Months
              </Button>
              <Button
                btnStyles={{
                  padding: "10px 20px",
                  minWidth: "111px",
                  marginRight: "7px",
                }}
              >
                6 Months
              </Button>
              <Button
                btnStyles={{
                  padding: "10px 20px",
                  minWidth: "111px",
                  marginRight: "7px",
                }}
              >
                1 Year
              </Button>
              <Button btnStyles={{ padding: "10px 20px", minWidth: "111px" }}>
                4 Years
              </Button>
            </div>
            <div className={styles.circleAndLine}>
              <span className={styles.circle}></span>
              <span className={styles.line}></span>
              <span className={styles.circle}></span>
            </div>
            <div className={styles.myButtons}>
              <div onClick={() => setPopup(false)}>
                <Button background="#E7E7E7" color="#323232">
                  Approve
                </Button>
              </div>
              <div onClick={() => setPopup(false)}>
                {" "}
                <Button>Convert & Stake</Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={styles.modal} onClick={() => setPopup(false)}></div>
    </>
  );
};

export default StakeModal;
