import React, {useState, useEffect} from "react";
import Input from "../../../Input/Input";
import styles from "./MakeOfferPopup.module.css";
import {BsChevronUp, BsChevronDown} from "react-icons/bs";
import {
    handleRequest,
    calculateFlexibleDays,
    validateIfProfileIsUpdated
} from "../../../../utilities/common.utility";
import {toast} from "react-toastify";
import {
    fixedLoanOffer,
    flexibledLoanOffer
} from "../../../../utilities/Contracts/OwnaLendingAndBorrowing/ownaLendingAndBorrowingContractFunctions";
import {CONTRACT_DETAILS} from "../../../../utilities/Contracts/contractsDetails";
import {
    approveToken,
    getBalance
} from "../../../../utilities/Contracts/OwnaToken/ownaTokenContract";
import {useDataContext} from "../../../../context/context";
import {useNavigate} from "react-router-dom";
import {getWeb3} from "../../../../utilities/web3.utility";
import ModalButton from "../../../modalBtn/ModalBtn";
import {Box, Tab, Tabs} from "@mui/material";
import TabPanel from "../../../Tab/TabPanel";
import {OfferType} from "../../../../utilities/types";

const Fixed_Offer_Inputs = (loanMaxValue: string, maxBorrowCapacity: string) => [
    {
        label: "Entry Fee* %",
        type: "number",
        name: "entryfee",
        required: true,
        disable: true,
        defaultValue: 2,
        background: "#f7f7f9",
        placeholder: "Entry Fee"
    },
    {
        label: "Minimum Loan Amount*",
        type: "number",
        min: 1,
        max: Number(loanMaxValue) - 1,
        name: "loanMinValue",
        required: true,
        placeholder: "Minimum Loan Amount",
        tooltip: "This is the minimum amount that be borrowed on your escrowed money",
    },
    {
        label: `Maximum Loan Amount (Max ${maxBorrowCapacity} USDC)*`,
        type: "number",
        min: 2,
        max: maxBorrowCapacity,
        name: "loanMaxValue",
        required: true,
        placeholder: "Maximum Loan Amount",
        tooltip: "This is the maximum amount that be borrowed on your escrowed money",
    },
    {
        label: "APR* % ( Maximum 25% debt per loan)",
        type: "number",
        name: "apr",
        required: true,
        placeholder: "APR",
        tooltip: "This is the yearly interest rates you are agreeing with the borrower",
    },
    {
        label: "Days*",
        type: "number",
        name: "days",
        required: true,
        placeholder: "Days"
    }
];

const Flexible_Offer_Inputs = (loanMaxValue: string, maxBorrowCapacity: string) => [
    {
        label: "Entry Fee* %",
        type: "number",
        name: "entryfee",
        required: true,
        disable: true,
        background: "#f7f7f9",
        placeholder: "Entry Fee"
    },
    {
        label: "APR* % ( Maximum 25% debt per loan)",
        type: "number",
        required: true,
        name: "apr",
        placeholder: "APR",
        tooltip: "This is the yearly interest rates you are agreeing with the borrower",
    },
    {
        label: "Minimum Loan Amount*",
        required: true,
        type: "number",
        min: 1,
        max: Number(loanMaxValue) - 1,
        name: "loanMinValue",
        placeholder: "Minimum Loan Amount",
        tooltip: "This is the minimum amount that be borrowed on your escrowed money",
    },
    {
        label: `Maximum Loan Amount (Max ${maxBorrowCapacity} USDC)*`,
        min: 2,
        max: maxBorrowCapacity,
        required: true,
        type: "number",
        name: "loanMaxValue",
        placeholder: "Maximum Loan Amount",
        tooltip: "This is the maximum amount that be borrowed on your escrowed money",
    },
    {
        label: "Maximum Acceptable Debts % (Max 25% YDT - include entry fee)*",
        min: 3,
        max: 25,
        required: true,
        type: "number",
        name: "maximumacceptabledebt",
        placeholder: "Maximum Acceptable Debts",
        tooltip: "This is maximum debt/interests that you are allowing despite the yearly rate.",
    }
];

const FixOfferTabIndex = 0;
const FlexibleOfferTabIndex = 1;

const MakeOfferPopup = ({onClose, mintDetails}: any) => {
    const [loader, setLoader] = useState(false);
    const [debtPercentage, setDebtPercentage] = useState(0);
    const [debtLimitIncreased, setDebtLimitIncreased] = useState(false);
    const [period, setPeriod] = useState<any>("");
    const [periodVisible, setPeriodVisible] = useState(false);
    const [customPeriod, setCustomPeriod] = useState(false);
    const [steps, setSteps] = useState({step1: true, step2: false});
    const [msgs, setMsgs] = useState<any>({
        name: null,
        msg: "",
        show: false,
        apr: null,
        error: false
    });
    const [activeTab, setActiveTab] = useState<number>(FixOfferTabIndex);
    const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
        setActiveTab(tab);
    };

    const {connect} = useDataContext();
    const navigate = useNavigate();

    const [values, setValues] = useState<any>({
        period: "",
        data: [
            {
                entryfee: "2",
                apr: "",
                loanMinValue: "",
                loanMaxValue: ""
            },
            {
                entryfee: "2",
                apr: "",
                loanMinValue: "",
                loanMaxValue: "",
                maximumacceptabledebt: ""
            }
        ]
    });

    let inputs: Array<any> = [];
    if (activeTab == FixOfferTabIndex) {
        inputs = Fixed_Offer_Inputs(values.data[0].loanMaxValue, mintDetails.maxBorowCapacity);
    } else {
        inputs = Flexible_Offer_Inputs(values.data[1].loanMaxValue, mintDetails.maxBorowCapacity);
    }


    const handleError = (e: any) => {
        if (
            msgs.apr &&
            Number(e?.target?.value) > msgs.apr &&
            e.target.name === "apr"
        ) {
            setMsgs({...msgs, error: true});
        } else {
            setMsgs({...msgs, error: false});
        }
    };

    const onChange = (title: string, e: any) => {
        let data = null;
        if (activeTab == FixOfferTabIndex) {
            data = {...values.data[0]};

            //handle Error
            handleError(e);
            if (e.target.name === "period") {
                handleLoanDuration(
                    e.target.name,
                    data.loanMaxValue,
                    +e.target.value,
                    data.apr
                );
            } else {
                if (e.target.name === "apr") {
                    handleLoanDuration(
                        e?.target?.name,
                        data.loanMaxValue,
                        null,
                        e.target.value
                    );
                } else {
                    handleLoanDuration(e?.target?.name, e?.target?.value, null, data.apr);
                }
            }
        } else {
            data = {...values.data[1]};
        }

        if (e.target.name === "period") {
            setValues({...values, period: e.target.value});
        } else {
            data = {...data, [e.target.name]: e.target.value};
            let payload = [...values.data];
            payload[activeTab] = data;
            setValues({...values, data: payload});
        }
    };

    const handleLoanDuration = (name: any, value: any, period: any, apr: any) => {
        if (
            name === "loanMaxValue" ||
            name === "apr" ||
            (name === "period" && value > 0)
        ) {
            let debt = percentage(+value, +apr) / 365;
            debt = debt * (period ? period : values.period);
            setDebtPercentage(debt);

            const twentyFivePercent = percentage(+value, 25);

            const onePercant = percentage(+value, 1);

            const valueToCountApr =
                (onePercant / 365) * (period ? period : values.period);

            const APR = twentyFivePercent / valueToCountApr;
            if (period || values.period) {
                if (
                    value <= 0 ||
                    (name === "loanMaxValue" && !values.period) ||
                    (name === "period" && !period)
                ) {
                    setMsgs({
                        ...msgs,
                        name: "apr",
                        msg: `(Max APR:${APR?.toFixed(2)}%)`,
                        show: false,
                        error: false
                    });
                    return;
                }

                setMsgs({
                    ...msgs,
                    name: "apr",
                    msg: `(Max APR:${APR?.toFixed(2)}%)`,
                    show: true,
                    apr: APR.toFixed(2),
                    ...(Number(apr ? apr : values.data[0].apr) > APR
                        ? {error: true}
                        : {error: false})
                });
            }
            if (debt > twentyFivePercent) {
                setDebtLimitIncreased(true);
                return true;
            } else {
                setDebtLimitIncreased(false);
                return false;
            }
        } else {
            setDebtLimitIncreased(false);
            return false;
        }
    };

    function percentage(num: number, percantToGet: number) {
        return (percantToGet / 100) * num;
    }

    const readBalance = async (connect?: any) => {
        const web3 = await getWeb3();
        let balance = await getBalance(connect);
        balance = web3.utils.fromWei(balance.toString(), "mwei");
        return Number(balance);
    };

    const periods = [7, 14, 30, 60, 90];

    const handleSubmitOffer = async (e: any) => {
        try {
            e.preventDefault();
            if (!validateIfProfileIsUpdated()) {
                toast.info("Please complete profile first");
                return navigate("/myprofile");
            }
            toast.info("Please do not close or reload page");
            const balance = await readBalance(connect);
            if (+values?.data[0].loanMaxValue > balance) {
                return toast.error("Insufficent funds");
            }
            setLoader(true);
            const approvalOfTokens = await approveToken(
                CONTRACT_DETAILS?.borrowAndLending?.address,
                values.data[activeTab].loanMaxValue
            );
            let duration = null;

            if (!approvalOfTokens?.status) {
                throw new Error("Something went wrong while approving the tokens");
            }
            setSteps({...steps, step1: false, step2: true});

            let offerTransactionResponse = undefined;
            if (activeTab === 0) {
                offerTransactionResponse = await fixedLoanOffer(
                    +values?.period * 24 * 60 * 60,
                    200,
                    +values?.data[0].apr,
                    values?.data[0].loanMinValue,
                    values?.data[0].loanMaxValue,
                    mintDetails?.tokenId,
                    CONTRACT_DETAILS?.mint?.address,
                    CONTRACT_DETAILS?.ownaToken?.address,
                    259200,
                    mintDetails?.ownerAddress
                );
            }

            if (activeTab === 1) {
                const {loanMaxValue, maximumacceptabledebt, apr} = values?.data[1];
                duration = calculateFlexibleDays({
                    loanMaxValue,
                    apr,
                    maximumacceptabledebt: Number(maximumacceptabledebt) - 2
                });

                offerTransactionResponse = await flexibledLoanOffer({
                    duration: +duration * 24 * 60 * 60,
                    apr: +values?.data[1].apr,
                    minLoan: +values?.data[1].loanMinValue,
                    maxLoan: +values?.data[1].loanMaxValue,
                    nftId: +mintDetails?.tokenId,
                    acceptableDebt: +values?.data[1].maximumacceptabledebt,
                    borrowerAddress: mintDetails?.ownerAddress
                });
            }
            if (
                offerTransactionResponse?.status &&
                offerTransactionResponse?.transactionHash
            ) {
                const {data, ...otherValues} = values;
                const payload = {
                    ...otherValues,
                    ...(activeTab === 0 && {...values.data[0]}),
                    ...(activeTab === 1 && {...values.data[1]}),
                    mintId: mintDetails?._id,
                    type: activeTab === 0 ? "fixed" : "flexible",
                    status: "pending",
                    toAddress: mintDetails?.ownerAddress?.toLowerCase(),
                    duration,
                    fromAddress: connect
                };

                if (activeTab === 0) {
                    payload["fixedOfferId"] = Number(
                        offerTransactionResponse?.events?.FixedLoan?.returnValues?.fixedId
                    );
                }
                if (activeTab === 1) {
                    payload["flexibleOfferId"] = Number(
                        offerTransactionResponse?.events?.FlexibleLoan?.returnValues
                            ?.flexibleId
                    );
                }

                const addingOffer = await handleRequest("post", "offer/addOffer", {
                    payload
                });

                if (addingOffer?.data?.success) {
                    onClose(false);
                    setSteps({...steps, step1: true, step2: false});
                    toast.success(addingOffer?.data?.message || "Offer submitted");
                } else {
                    toast.error(addingOffer?.data?.message);
                    setLoader(false);
                }
                return;
            }
            throw new Error("Something went wrong while adding offer");
        } catch (err) {
            setLoader(false);
            setSteps({...steps, step1: true, step2: false});
            console.log(
                "🚀 ~ file: MakeOfferPopup.js ~ line 118 ~ handleSubmitOffer ~ err",
                err
            );
        }
    };
    const handleBtnDisable = () => {
        if (activeTab === 0) {
            return Object?.values(values?.data[0])?.some(
                (val) => val === null || val === ""
            );
        } else {
            return Object?.values(values?.data[1])?.some(
                (val) => val === null || val === ""
            );
        }
    };
    useEffect(() => {
        if (period && period !== "" && activeTab === 0) {
            setValues({...values, period});
            return;
        }
        if (activeTab === 1) {
            setValues({...values, period: undefined});
            return;
        }
    }, [period, activeTab]);

    return (
        <>
            <section className={styles.popupWrapper}>
                <div className={`wrapper ${styles.popup}`}>
                    {debtLimitIncreased && (
                        <p className={styles.textAlert}>
                            Keep the maximum borrowing debt below 25%. With current setting you
                            reach {debtPercentage.toFixed(2)}%.
                        </p>
                    )}
                    <Box sx={{borderBottom: 1, borderColor: 'divider', width: '100%'}}>
                        <Tabs
                            onChange={handleTabChange}
                            aria-label="dashboard tabs"
                            variant="fullWidth"
                            textColor="secondary"
                            indicatorColor="secondary"
                            value={activeTab}
                        >
                            <Tab label="Fixed" id="fixed-tab-0" aria-controls="dashboard-tabpanel-0"
                                 sx={{fontSize: 16, fontWeight: 'bold'}}/>
                            <Tab label="Flexible" id="flexible-tab-1" aria-controls="dashboard-tabpanel-1"
                                 sx={{fontSize: 16, fontWeight: 'bold'}}/>
                        </Tabs>
                    </Box>
                    <TabPanel value={activeTab} index={FixOfferTabIndex} className="w-full">
                        <form onSubmit={handleSubmitOffer}>
                            {/*Fixed*/}
                            {inputs.map((input, i) => (
                                <Input
                                    {...input}
                                    key={i}
                                    value={values.data[activeTab][input.name]}
                                    msgs={msgs}
                                    onChange={(e: any) => onChange(OfferType.Fixed, e)}
                                />
                            ))}
                            <div className={styles.buttonContainer}>
                                <ModalButton
                                    btn1="Approve"
                                    btn2="Make Offer"
                                    steps={steps}
                                    loading={loader}
                                    disabled={
                                        loader ||
                                        handleBtnDisable() ||
                                        debtLimitIncreased ||
                                        msgs?.error
                                    }
                                />
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel value={activeTab} index={FlexibleOfferTabIndex} className="w-full">
                        <form onSubmit={handleSubmitOffer}>
                            {/*Flexible*/}
                            {inputs.map((input, i) => (
                                <Input
                                    {...input}
                                    key={i}
                                    value={values.data[activeTab][input.name]}
                                    onChange={(e: any) => onChange(OfferType.Flexible, e)}
                                />
                            ))}
                            <div className={styles.buttonContainer}>
                                <ModalButton
                                    btn1="Approve"
                                    btn2="Make Offer"
                                    steps={steps}
                                    loading={loader}
                                    disabled={
                                        loader ||
                                        handleBtnDisable() ||
                                        debtLimitIncreased ||
                                        msgs?.error
                                    }
                                />
                            </div>
                        </form>
                    </TabPanel>
                </div>
            </section>

            <div
                className={styles.modal}
                onClick={() => {
                    if (loader) {
                        return;
                    }
                    onClose(false);
                }}
            ></div>
        </>
    );
};

export default MakeOfferPopup;
