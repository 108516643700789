import { CONTRACT_DETAILS } from "../contractsDetails";
import {
  getContractMethods,
  getGasPriceFromStation,
  getWeb3,
} from "../../web3.utility";

const mintOwnaNft = async (Owner:any, _nftTitle:any, _uri:any) => {
  try {
    const contractDetails = CONTRACT_DETAILS.mint;
    const contract = await getContractMethods(
      contractDetails?.ABI,
      contractDetails?.address
    );

    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);

    const estimatedGasFees = await contract.methods
      .mint(CONTRACT_DETAILS.borrowAndLending.address, Owner, _nftTitle, _uri)
      .estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    const responsFixedLoanOffer = await contract.methods
      .mint(CONTRACT_DETAILS.borrowAndLending.address, Owner, _nftTitle, _uri)
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation,
      });
    return responsFixedLoanOffer;
  } catch (error) {
    console.log(CONTRACT_DETAILS.mint.address, "contract.address");
    console.log(error, "mint Nft error");
  }
};

const approveMintingToken = async (spender:any, tokenId:any) => {
  try {
    const web3 = await getWeb3();
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);

    const contractDetails = CONTRACT_DETAILS.mint;

    const contract = await getContractMethods(
      contractDetails.ABI,
      contractDetails.address
    );
   
    const estimatedGasFees = await contract.methods
      .approve(spender, tokenId)
      .estimateGas({ from: accounts[0] });

    const gasPriceFromStation = await getGasPriceFromStation(web3, chainId);

    const responsFixedLoanOffer = await contract.methods
      .approve(spender, tokenId)
      .send({
        from: accounts[0],
        gas: estimatedGasFees,
        gasPrice: gasPriceFromStation,
      });
    return responsFixedLoanOffer;
  } catch (err) {
    console.log(
      "🚀 ~ file: ownaTokenContract.ts ~ approveToken ~ err",
      err
    );
  }
};

export { mintOwnaNft, approveMintingToken };
