import React from 'react';
import {OfferStatus, OfferType} from "../../../../../utilities/types";
import {getOfferStateLabel, getOfferTypeLabel} from "../../../../../utilities/onboard";

type BidTableProps = {
    image: string;
    maxDebtAllowance: string;
    status: string;
    type: string;
    apr: string;
    volume: string;
    lender: string;
    days: number;
};

const BidTable: React.FC<BidTableProps> = (
    {
        image,
        maxDebtAllowance,
        status,
        type,
        apr,
        volume,
        lender,
        days,
    }) => {
    const isFlexibleType = type == OfferType.Flexible;
    return (
        <table className="border-collapse border-b border-gray-400 w-full">
            <tbody>
            <tr>
                <td rowSpan={2} className="px-2 py-1">
                    <img src={image} alt="Bid" className="w-16 h-16 object-contain"/>
                </td>
                <td className="px-2 py-1 font-semibold">Loan</td>
                <td className="px-2 py-1 font-semibold">Status</td>
                <td className="px-2 py-1 font-semibold">Type</td>
                <td className="px-2 py-1 font-semibold">APR</td>
                <td className="px-2 py-1 font-semibold">{isFlexibleType ? "Max Loan" : "Days"}</td>
                <td className="px-2 py-1 font-semibold">Lender</td>
            </tr>
            <tr>
                <td className="px-2 py-1">{maxDebtAllowance}</td>
                <td className="px-2 py-1">{getOfferStateLabel(status as OfferStatus)}</td>
                <td className="px-2 py-1">{getOfferTypeLabel(type as OfferType)}</td>
                <td className="px-2 py-1">{apr}%</td>
                <td className="px-2 py-1 font-semibold">{isFlexibleType ? maxDebtAllowance : days}</td>
                <td className="px-2 py-1 max-w-[30px] overflow-ellipsis whitespace-nowrap overflow-hidden">{lender}</td>
            </tr>
            </tbody>
        </table>
    );
};

export default BidTable;