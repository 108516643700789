import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ContextProvider } from "./context/context";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import ScrollTotop from "./components/ScrollTotop";
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById("root") || document.createElement("div");
// @ts-ignore
const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        {" "}
        <BrowserRouter>
            <ScrollTotop />
            <ContextProvider>
                <ToastContainer />
                <App />
            </ContextProvider>
        </BrowserRouter>
    </React.StrictMode>
);
