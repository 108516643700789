import React, {ReactNode} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {FiChevronDown} from "react-icons/fi";
import styles from "./nft-popup.module.css";

type AssetAttribute = {
    name: string;
    type: string;
}

type Props = {
    description: string;
    storage: string;
    estimate: string;
    properties: Array<AssetAttribute>;
    levels: Array<AssetAttribute>;
    stats: Array<AssetAttribute>;
};

const AboutTab: React.FC<Props> = ({
       description,
       storage,
       estimate,
       properties,
       levels,
       stats,
   }) => {
    return (
        <div className="sm:grid grid-cols-2 gap-2 max-h-[280px]">
            <div>
                {/*<Accordion defaultExpanded={true}>*/}
                {/*    <AccordionSummary*/}
                {/*        expandIcon={<FiChevronDown/>}*/}
                {/*        aria-controls="nft-info-content"*/}
                {/*        id="nft-info-header"*/}
                {/*        className={styles.aboutAccordionHeader}*/}
                {/*    >*/}
                {/*        <Typography>Information</Typography>*/}
                {/*    </AccordionSummary>*/}
                {/*    <AccordionDetails>*/}
                {/*        <div className={"grid grid-cols-3 gap-2"}>*/}
                {/*            <div className={styles.aboutInfoContainer}>*/}
                {/*                <div className={styles.aboutInfoName}>Storage Fee</div>*/}
                {/*                <div className={styles.aboutInfoValue}>{storage}</div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.aboutInfoContainer}>*/}
                {/*                <div className={styles.aboutInfoName}>Estimate</div>*/}
                {/*                <div className={styles.aboutInfoValue}>{estimate}</div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </AccordionDetails>*/}
                {/*</Accordion>*/}

                {/* Properties */}
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<FiChevronDown/>}
                        aria-controls="nft-attributes-content"
                        id="nft-attributes-header"
                        className={styles.aboutAccordionHeader}
                    >
                        <Typography>Properties</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={"flex flex-wrap gap-2 py-2"}>
                            {
                                properties.map((item, index) => (
                                    <div key={index} className={styles.aboutAttributeContainer}>
                                        <div className={styles.aboutAttributeName}>{item.name}</div>
                                        <div className={styles.aboutAttributeValue}>{item.type}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>

                {/* Levels */}
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<FiChevronDown/>}
                        aria-controls="nft-attributes-content"
                        id="nft-attributes-header"
                        className={styles.aboutAccordionHeader}
                    >
                        <Typography>Levels</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={"flex flex-wrap gap-2 py-2"}>
                            {
                                levels.map((item, index) => (
                                    <div key={index} className={styles.aboutAttributeContainer}>
                                        <div className={styles.aboutAttributeName}>{item.name}</div>
                                        <div className={styles.aboutAttributeValue}>{item.type}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>

                {/* Stats */}
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<FiChevronDown/>}
                        aria-controls="nft-attributes-content"
                        id="nft-attributes-header"
                        className={styles.aboutAccordionHeader}
                    >
                        <Typography>Stats</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={"flex flex-wrap gap-2 py-2"}>
                            {
                                stats.map((item, index) => (
                                    <div key={index} className={styles.aboutAttributeContainer}>
                                        <div className={styles.aboutAttributeName}>{item.name}</div>
                                        <div className={styles.aboutAttributeValue}>{item.type}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<FiChevronDown/>}
                        aria-controls="nft-description-content"
                        id="nft-description-header"
                        className={styles.aboutAccordionHeader}
                    >
                        <Typography>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={styles.aboutDescription}>{description}</Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

export default AboutTab;
