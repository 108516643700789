import React, {ChangeEvent, useEffect, useState} from "react";
import Input from "../../Input/Input";
import UploadingPhoto from "../../UploadingPhoto/UploadingPhoto";
import CheckBox from "../../Checkbox/CheckBox";
import Button from "../../Button/Button";
import {BsChevronDown, BsChevronUp} from "react-icons/bs";
import styles from "./AppointmentInfo.module.css";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {handleRequest, validateIfProfileIsUpdated} from "../../../utilities/common.utility";
import {useDataContext} from "../../../context/context";

interface Values {
    category: string;
    origin: File | null;
    description: string;
    assetState: string;
    facing: string;
    back: string;
    leftF: string;
    rightF: string;
    walletAddress: string;
    status: string;
}

const AppointeMentInfo = () => {
    let navigate = useNavigate();
    const {connect} = useDataContext();
    const [rightAsset, setRightAsset] = useState(false);
    const [notFake, setNotFake] = useState(false);
    const [loader, setLoader] = useState(false);
    const [categoryVisible, setCategoryVisible] = useState(false);
    const [assetStateVisible, setAssetStateVisible] = useState(false);
    const [prevAppointment] = useState(null);
    const [values, setValues] = useState<Values>({
        category: "",
        origin: null,
        description: "",
        assetState: "",
        facing: "",
        back: "",
        leftF: "",
        rightF: "",
        walletAddress: "",
        status: "pending"
    });

    const categories = [
        "Watch",
        "Jewelry",
        "Stone",
        "Gold",
        "Paintings & Photos"
    ];

    const assetStateData = [
        "Brand New",
        "Excellent",
        "VeryGood",
        "Moderate",
        "Bad"
    ];

    useEffect(() => {
        if (connect) {
            getData();
        }
    }, [connect]);
    const getData = async () => {
        setValues({...values, walletAddress: connect});
    };
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({...values, [e.target.name]: e.target.value});
    };

    const onChangeFile = (name: string, e: ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.files &&
            e.target.files[0] &&
            !e.target.files[0].type.includes('image')
        ) {
            return toast.error('Only image files are allowed');
        }
        setValues({...values, [name]: e.target.files && e.target.files[0]});
    };

    const handleBtnDisable = () => {
        const isEmpty = Object.values(values).some(
            (val) => val === null || val === ""
        );
        return loader || !notFake || !rightAsset || !connect || isEmpty;
    };

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            if (!validateIfProfileIsUpdated()) {
                toast.info("Please complete profile first");
                return navigate("/myprofile?redirect=appointment");
            }
            e.preventDefault();
            setLoader(true);
            const formData = new FormData();
            for (const [key, value] of Object.entries(values)) {
                formData.append(key, value as File);
            }
            const res = await handleRequest("post", "appointments/create", formData as any);
            if (res.data.success) {
                setLoader(false);
                toast.success("Appointment created successfully");
                navigate("/connect");
            } else {
                toast.error(res.data.message);
            }
        } catch (err: any) {
            toast.error(err.message);

            setLoader(false);
        }
    };

    const {category, assetState} = values;
    return (
        <section className={`wrapper ${styles.wrapper}`}>
            <div className="container">
                <form onSubmit={submit} method={'post'}>
                    <h1 className="title">
                        What asset are willing to bring to your appointment?
                    </h1>
                    {!connect && (
                        <span className={styles.connectWalletMessage}>
                          {"Please connect your wallet "}
                        </span>
                    )}

                    <label className={styles.label}>Category*</label>
                    <div className={styles.categoryContainer}>
                        <div
                            className={styles.dropdownAndIcon}
                            onClick={() => setCategoryVisible((prev) => !prev)}
                        >
                            <label className={`${styles.category} mb-0`} aria-disabled={true}>
                                {category ? (
                                    category
                                ) : (
                                    <span className={styles.placeholeder}>Select</span>
                                )}
                            </label>
                            {categoryVisible ? (
                                <BsChevronUp className={styles.arrow}/>
                            ) : (
                                <BsChevronDown className={styles.arrow}/>
                            )}
                        </div>
                        {categoryVisible && (
                            <div className={styles.categories}>
                                {categories.map((el, i) => (
                                    <p
                                        className={`${styles.categoryItem}`}
                                        key={i}
                                        onClick={() => {
                                            setCategoryVisible((prev) => !prev);
                                            setValues({...values, category: el});
                                        }}
                                    >
                                        {el}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>

                    <UploadingPhoto
                        onChange={onChangeFile}
                        prevAppointment={prevAppointment}
                        values={values}
                    />

                    <Input
                        label="Description*"
                        type="text"
                        name="description"
                        placeholder="Description"
                        value={values["description"]}
                        onChange={onChange}
                    />

                    <label className={styles.label}>State of the Asset*</label>
                    <div className={styles.categoryContainer}>
                        <div
                            className={styles.dropdownAndIcon}
                            onClick={() => setAssetStateVisible((prev) => !prev)}
                        >
                            <p className={`${styles.category} mb-0`}>
                                {assetState ? (
                                    assetState
                                ) : (
                                    <span className={styles.placeholeder}>Select</span>
                                )}
                            </p>
                            {assetStateVisible ? (
                                <BsChevronUp className={styles.arrow}/>
                            ) : (
                                <BsChevronDown className={styles.arrow}/>
                            )}
                        </div>
                        {assetStateVisible && (
                            <div className={styles.categories}>
                                {assetStateData.map((el, i) => (
                                    <p
                                        className={styles.categoryItem}
                                        key={i}
                                        onClick={() => {
                                            setAssetStateVisible((prev) => !prev);
                                            setValues({...values, assetState: el});
                                        }}
                                    >
                                        {el}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className={styles.checkBoxContainer}>
                        <CheckBox
                            id="right_asset"
                            checked={rightAsset}
                            setChecked={setRightAsset}
                            label="I certify that all the photos are taken by me and are showing the asset brought to the appointment."
                        />{" "}
                        <CheckBox
                            id="not_fake"
                            checked={notFake}
                            setChecked={setNotFake}
                            label="I certify the asset is not fake, and fully understand that Owna’s partners will keep it if fake."
                        />
                    </div>
                    <Button disabled={handleBtnDisable()} loader={loader} type="submit">
                        Appointment
                    </Button>
                </form>
            </div>
        </section>
    );
};

export default AppointeMentInfo;
