import React, {useEffect, useState} from "react";
import styles from "./ClaimOffers.module.css";
import { handleRequest, percentage } from "../../../../utilities/common.utility";
import LendingDashboardItem from "../LendingDahboardComponent";
import {
    claimFundsByLender
} from "../../../../utilities/Contracts/OwnaLendingAndBorrowing/ownaLendingAndBorrowingContractFunctions";
import {toast} from "react-toastify";
import {
    fixedBorrow,
    readDynamicInterest,
    flexibleBorrow,
    cancelOffer
} from "../../../../utilities/Contracts/OwnaLendingAndBorrowing/ownaLendingAndBorrowingContractFunctions";
import {getWeb3} from "../../../../utilities/web3.utility";
import moment from "moment";
import p2poffers from "../../../../images/dashboard_2.svg";
import {useNavigate} from "react-router-dom";
import {getNftStateLabel, getOfferTypeLabel} from "../../../../utilities/onboard";
import {OfferBorrowStatus, OfferStatus, OfferType} from "../../../../utilities/types";
import {useDataContext} from "../../../../context/context";

interface ClaimOffersProps {
    isValidNetwork: boolean;
    hidePlaceholder?: boolean;
}

const ClaimOffers = ({isValidNetwork}: ClaimOffersProps) => {
    const [offers, setOffers] = useState<any>(null);
    const [loader, setLoader] = useState({status: false, id: null});
    const { connect } = useDataContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (connect && isValidNetwork) {
            getOffers();
        } else {
            setOffers([]);
        }
    }, [connect, isValidNetwork]);

    const getOffers = async () => {
        const res = await handleRequest(
            "get",
            `offer/getOffersByStatus?address=${connect}&&lender=true`
        );

        const {offers} = res.data;
        let refunedItems = [];
        let notRefunedItems = [];
        let updatedOffers = [];
        if (offers && offers.length > 0) {
            refunedItems = offers.filter(
                (item: any) => item.status === "accepted" && item?.borrow?.status === "refunded"
            );
            notRefunedItems = offers.filter((item: any) => item?.borrow?.status !== "refunded");
            updatedOffers = notRefunedItems.concat(refunedItems);
        }

        let data = await Promise.all(
            updatedOffers.map(async (item: any) => {
                ///////web3 part///////
                const web3 = await getWeb3();
                let contractSyncBorrowInfo = undefined;
                let contractInterest = undefined;
                let amountOfBorrower = undefined;
                if (item?.type === "fixed") {
                    contractSyncBorrowInfo = await fixedBorrow(item?.mintId?.tokenId);
                } else {
                    contractSyncBorrowInfo = await flexibleBorrow(item?.mintId?.tokenId);
                }
                contractInterest = await readDynamicInterest(
                    item?.mintId?.tokenId,
                    item.type === "fixed" ? item.fixedOfferId : item.flexibleOfferId
                );
                contractInterest = web3.utils.fromWei(contractInterest || "0", "mwei");
                let contractOutStandingLoan = web3.utils.fromWei(
                    contractSyncBorrowInfo.loanAmount || "0",
                    "mwei"
                ) as any;

                contractSyncBorrowInfo = web3.utils.fromWei(amountOfBorrower || "0", "mwei");

                contractOutStandingLoan =
                    Number(contractOutStandingLoan) + Number(contractInterest);

                //calculate days left
                var a = moment(item.createdAt);
                const totalDaysToRefund = moment();
                const dateInDays = totalDaysToRefund.diff(a, "days");
                const daysLeft = Number(item?.period) - dateInDays;

                // Lending Status - Type - APR - Outstanding loan / Volume - Daily interest - Lender
                let info = [
                    {
                        data: {
                            ...item
                        }
                    },
                    {
                        img: item.mintId.fileUrl,
                        link: `/marketplace?nft_view=${item.mintId._id}`
                    },
                    {title: "Status ", text: getNftStateLabel(item.status, item.borrow?.status)},
                    {title: "Type ", text: getOfferTypeLabel(item.type)},
                    {title: "APR ", text: item.apr + "%"},
                    {
                        title: item.status === OfferStatus.Accepted ? "Outstanding loan" : "Volume",
                        text:
                            item.status === OfferStatus.Accepted
                                ? Number(contractOutStandingLoan).toFixed(2) + " USDC"
                                : `${item.loanMinValue} - ${item.loanMaxValue} USDC `
                    },
                    {
                        title: "Daily interest",
                        text: `${(percentage(+item.borrow?.amount ?? 0, +item.apr) / 365)?.toFixed(5)} USDC`
                    },
                    {
                        title: "Lender ",
                        text: item.fromAddress?.slice(0, 4) + "..." + item.fromAddress?.slice(-4)
                    }
                ];
                if (item.type === OfferType.Fixed && item.borrow?.status !== OfferBorrowStatus.Refunded) {
                    info.splice(4, 0, {
                        title: ["accepted", "sellIt"].includes(item.status) ? "Days left" : "Days",
                        text: ["accepted", "sellIt"].includes(item.status)
                            ? Math.max(0, Number(daysLeft))
                            : item.period
                    });
                }
                if (item?.type === OfferType.Flexible) {
                    info.splice(4, 0, {
                        title: "Max Debt Allowance",
                        text: item.maximumacceptabledebt + "%"
                    });
                }

                return {
                    info
                };
            })
        );
        setOffers(data);
    };

    const claimFunds = async (data: any) => {
        const {item, action} = data;
        try {
            setLoader({status: true, id: item._id});
            toast.info("Please do not close or reload page");
            if (action === "claim") {
                await claimFundsByLender(
                    item.mintId.tokenId,
                    item.type === "fixed" ? item.fixedOfferId : item.flexibleOfferId
                );
            } else {
                await cancelOffer(
                    item.mintId.tokenId,
                    item.type === "fixed" ? item.fixedOfferId : item.flexibleOfferId
                );
            }

            const res = await handleRequest("put", `offer/updateOffer`, {
                id: item._id,
                status: action === "claim" ? "claimed" : "cancelled"
            });
            if (res.data.success) {
                toast.success(`Offer ${action === "claim" ? "claimed" : "cancelled"} successfully`);
                setLoader({status: false, id: null});
                getOffers();
            }
        } catch (err) {
            setLoader({status: false, id: null});
            console.log(err, "error==>");
        }
    };
    return (
        <>
            {offers?.length > 0 && (
                <div className={`${styles.tabBtn} my-5 w-100`}>
                    <button onClick={() => navigate("/marketplace")}>MarketPlace</button>
                </div>
            )}
            {offers && !offers.length && (
                <>
                    <img className={styles.placeHolderimg} src={p2poffers} alt=""/>
                </>
            )}

            {offers &&
                offers.length > 0 &&
                connect &&
                offers.map((el: any, i: number) => (
                    <LendingDashboardItem
                        key={i}
                        data={el.info}
                        loading={loader}
                        disabled={!!el?.disabled}
                        lender
                        claimFunds={claimFunds}
                    />
                ))}
            {!offers?.length && (
                <div className={`${styles.tabBtn} my-5`}>
                    <button onClick={() => navigate("/marketplace")}>MarketPlace</button>
                </div>
            )}
        </>
    );
};
export default ClaimOffers;
