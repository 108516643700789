import React, { useEffect, useState } from "react";
import { useDataContext } from "../../../context/context";
import Button from "../../Button/Button";
import CheckBox from "../../Checkbox/CheckBox";
import Input from "../../Input/Input";
import styles from "./MyProfile.module.css";
import { handleRequest } from "../../../utilities/common.utility";
import { toast } from "react-toastify";
import { KYCInputData } from "../../../utilities/data";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connectWithMetamask } from "../../../utilities/web3.utility";
import { ENV } from "../../../config/config";

const MyProfile = () => {
    const [searchParams] = useSearchParams();
    const {scrollToTop, setConnect, connect} = useDataContext();
    const [checked, setChecked] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [values, setValues] = useState<any>({
        firstName: "",
        lastName: "",
        email: "",
        walletAddress: connect,
        id: "",
        proofOfAddress: ""
    });

    let navigate = useNavigate();
    useEffect(() => {
        if (connect) {
            setValues({...values, walletAddress: connect});
            getUserInfo();
        }
    }, [connect]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({...values, [e.target.name]: e.target.value});
    };

    const connectHandler = async () => {
        let address = await connectWithMetamask();
        if (address)
            setConnect(address);
        return true;
    };

    const getUserInfo = async () => {
        try {
            const userInfo = await handleRequest(
                "get",
                `user/walletAddress/${connect}`,
                {}
            );
            if (userInfo.data.success && userInfo?.data?.user) {
                const {
                    firstName,
                    lastName,
                    email,
                    walletAddress,
                    id,
                    proofOfAddress
                } = userInfo?.data?.user;

                if (userInfo.data.user.email) {
                    setIsEditing(true);
                }

                setValues({
                    ...values,
                    firstName: firstName ? firstName : "",
                    lastName: lastName ? lastName : "",
                    email: email ? email : "",
                    walletAddress: walletAddress ? walletAddress : "",
                    id: id ? id : "",
                    proofOfAddress: proofOfAddress ? proofOfAddress : ""
                });
                setUserInfo(userInfo?.data?.user);
            } else {
            }
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const submit = async (e: any) => {
        e.preventDefault();
        if (!connect) {
            await connectHandler();
        }
        if (!isValidEmail()) {
            return toast.error("Please enter a valid email address");
        }
        try {
            setLoader(true);
            const formData = new FormData();
            let reqMethod = null;
            let route = null;
            for (const val in values) {
                // @ts-ignore
                formData.append(val, values[val]);
            }
            if (userInfo) {
                reqMethod = "put";
                route = `user/update/${connect}`;
            } else {
                reqMethod = "post";
                route = "user/register";
            }
            const res = await handleRequest(reqMethod, route, formData as any);
            if (res.data.success) {
                setLoader(false);
                ENV.encryptUserData({
                    ...values
                });
                toast.success("Profile updated successfully");

                // Go Next
                let redirectParam = searchParams.get('redirect')
                if (redirectParam) {
                    if (redirectParam == "appointment") {
                        // Go Appointment
                        navigate("/appintment");
                    } else {
                        // Go Make-Offer
                        navigate(`/marketplace?make_offer=${redirectParam}`);
                    }
                } else {
                    // Go Dashboard
                    navigate("/dashboard");
                }
            } else {
                toast.error(res.data.message);
            }
        } catch (err: any) {
            toast.error(err.response.data.message || err.message);
            setLoader(false);
        }
    };

    const handleBtnDisable = () => {
        let fieldsToValidate = JSON.parse(JSON.stringify(values));

        return Object.keys(fieldsToValidate).some(
            (key, i) => fieldsToValidate[key] === null || fieldsToValidate[key] === ""
        );
    };

    const downloadFile = (file: any) => {
        return (
            <a href={file} style={{color: "blue", textDecoration: "underline"}}>
                {"File"}
            </a>
        );
    };

    const isValidEmail = () => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email);
    };

    return (
        <section className={`${styles.container} wrapper`}>
            <div className="container">
                <form onSubmit={submit} action="#">
                    {KYCInputData.map((item, i) => (
                        <Input
                            {...item}
                            key={i}
                            disable={item.name == "walletAddress"}
                            value={item.name == "walletAddress" ? connect : values[item.name]}
                            onChange={onChange}
                        />
                    ))}

                    <div className={styles.labelContainer}>
                        <label className={`${styles.label} ${styles.text}`}> ID*</label>
                        <span className={styles.uploadBtn}>
                          <label htmlFor="id" className={styles.button}>
                            Upload
                            <input
                                type="file"
                                id="id"
                                name="id"
                                // disabled={userInfo ? true : false}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        [e.target.name]: e.target.files![0]
                                    });
                                }}
                                className={styles.input}
                            />
                          </label>
                          <span className={styles.fileName}>
                            {values?.id?.name
                                ? values?.id?.name
                                : values?.id
                                    ? downloadFile(values?.id)
                                    : ""}
                          </span>
                        </span>
                    </div>
                    <div className={styles.labelContainer}>
                        <label className={`${styles.label} ${styles.text}`}>
                            {" "}
                            Proof of Addresss*
                        </label>
                        <span className={styles.uploadBtn}>
                          <label htmlFor="proofOfAddress" className={styles.button}>
                            Upload
                            <input
                                type="file"
                                id="proofOfAddress"
                                name="proofOfAddress"
                                // disabled={userInfo ? true : false}
                                onChange={(e) => {
                                    const file = e.target?.files?.[0];
                                    setValues({
                                        ...values,
                                        [e.target.name]: e.target.files![0]
                                    });
                                }}
                                className={styles.input}
                            />
                          </label>
                          <span className={styles.fileName}>
                            {values?.proofOfAddress?.name
                                ? values?.proofOfAddress?.name
                                : values.proofOfAddress
                                    ? downloadFile(values?.proofOfAddress)
                                    : ""}
                          </span>
                        </span>
                    </div>

                    <CheckBox
                        id="approve_privacy"
                        checked={checked}
                        setChecked={setChecked}
                        label="Owna is a decentralized protocol and respects your privacy. Your email will be linked to the given wallet for notifications"
                    />

                    <div className={styles.buttonContainer} onClick={scrollToTop}>
                        {/*<Button*/}
                        {/*  loader={loader}*/}
                        {/*  disabled={!checked || loader || handleBtnDisable()}*/}
                        {/*  type="submit"*/}
                        {/*>*/}
                        {/*  {"Save"}*/}
                        {/*</Button>*/}
                        {!connect ?
                            <span className={styles.connectWalletMessage}>
                                {"Please connect your wallet "}
                              </span>
                            :
                            <Button
                                loader={loader}
                                disabled={!checked || loader}
                                type="submit"
                            >
                                Save
                            </Button>
                        }
                    </div>
                </form>
            </div>
        </section>
    );
};

export default MyProfile;
