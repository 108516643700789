import React, {useState} from "react";
import Button from "../../Button/Button";
import Input from "../../Input/Input";

import {handleRequest, validateIfProfileIsUpdated,} from "../../../utilities/common.utility";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import styles from "./BackOfficeEstimate.module.css";


const BackOfficeEstimate = () => {
  const [values, setValues] = useState<{
    [key: string]: string;
  }>({
    ownerAddress: "",
    amount: "",
    reference: "",
    status: "pending",
  });
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleBtnDisable = () => {
    const { ownerAddress, amount, reference } = values;
    return !ownerAddress.trim() || !amount || !reference.trim();
  };

  const sendEstimate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateIfProfileIsUpdated()) {
      toast.info("Please complete profile first");
      return navigate("/myprofile");
    }

    setLoader(true);
    try {
      let res = await handleRequest("post", "estimate/send", values);
      if (res.data.success) {
        toast.success("Estimate sent to the borrower");
        setLoader(false);
        setValues({
          ownerAddress: "",
          amount: "",
          reference: "",
          status: "pending",
        });
      }
    } catch (err:any) {
      setLoader(false);
      toast.error(err.response?.data?.message || err.message);
    }
  };

  const inputs = [
    {
      label: "Wallet Address",
      type: "text",
      name: "ownerAddress",
      required: true,
      onChange,
      placeholder: "Wallet Address",
    },
    {
      label: "Amount in USD",
      type: "number",
      min: 1,
      max: 900000000000,
      maxLength: 10,
      name: "amount",
      required: true,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (
            !(+e.target.value > 0) &&
            e.target.value !== "." &&
            e.target.value !== ""
        ) {
          return;
        }
        setValues({ ...values, [e.target.name]: e.target.value });
      },
      placeholder: "Amount",
    },
    {
      label: "Asset name/Reference",
      type: "text",
      required: true,
      maxLength: 20,
      name: "reference",
      onChange,
      placeholder: "Asset name/Reference",
    },
  ];

  return (
      <section className={`wrapper ${styles.estimate}`}>
        <form onSubmit={sendEstimate}>
          {inputs.map((input, i) => (
              <Input
                  {...input}
                  key={i}
                  value={values[input.name]}
              />
          ))}
          <div className={styles.buttonContainer}>
            <Button
                loader={loader}
                type="submit"
                disabled={handleBtnDisable()}
            >
              Send Estimate
            </Button>
          </div>
        </form>
      </section>
  );
};

export default BackOfficeEstimate;
