import { ENV } from "../../config/config";
const mintABI = require("./Minting/abi.utility.json");
const borrowAndLendingABI = require("./OwnaLendingAndBorrowing/OwnaLendingAndBorrowingContractABI.json");
const ownaTokenABImainnet = require("../Contracts/OwnaToken/OwnaTokenContractABI-mainnet.json");
const ownaTokenABI = require("../Contracts/OwnaToken/OwnaTokenContractABI.json");

const CONTRACT_DETAILS = {
  mint: {
    address: ENV.mint,
    ABI: mintABI,
  },
  borrowAndLending: {
    address: ENV.borrowLending,
    ABI: borrowAndLendingABI,
  },
  ownaToken: {
    address: ENV.ownaToken,
    ABI: ENV.chainId === 1101 ? ownaTokenABImainnet : ownaTokenABI,
  },
};

export { CONTRACT_DETAILS };
