import React, { useState } from 'react';

type Prop = {
    setSearch: any
    setSort: any
}
export default function Filter({ setSearch, setSort }: Prop) {
    const [isOpen, setIsOpen] = useState(false);
    const [text, showText] = useState('  Newest added')
    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectOption = (option: any, text: string) => {
        setSort(option);
        showText(text)
        setIsOpen(false); // Close the dropdown after selecting an option
    };
    return (
        <div className='w-full flex justify-between mb-3        '>
            <div className='w-[69%] px-4 gap-3 flex rounded-lg border-2 items-center h-[50px] border-gray-200'>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z" /></svg>
                <input type="text" onChange={(e) => { setSearch(e.target.value) }} placeholder='Search' className='focus:border-none focus:outline-none  h-full w-full' style={{ border: 0 }} />

            </div>
            <div className='w-[30%] px-1 md:!px-4 flex flex-col md:flex-row items-center rounded-lg gap-1 md:gap-3 h-[50px] border-gray-200 border-2'>
                <div className="relative   rounded-md   w-full ">
                    <button
                        className="inline-flex whitespace-nowrap items-center justify-center w-full gap-1 px-4 py-2 text-base text-gray-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                        onClick={handleToggleDropdown}
                        aria-haspopup="menu"
                        aria-expanded={isOpen}
                    >
                        Sorted by<span className="block font-semibold whitespace-nowrap text-gray-700">{text}</span>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 448 512"
                            className="text-black fill-current"
                            aria-hidden="true"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            {/* ... SVG path data */}
                        </svg>
                    </button>
                    {isOpen && (
                        <div className="absolute right-0 z-30 py-2 mt-2 overflow-hidden origin-top-right divide-y divide-transparent shadow-lg w-full bg-gray-50 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                            <button
                                className="text-black group flex w-full px-4 py-2 text-base font-medium"
                                onClick={() => handleSelectOption(1, '  Price: High to low')}
                            >
                                Price: High to low
                            </button>
                            <button
                                className="text-black group flex w-full px-4 py-2 text-base font-medium"
                                onClick={() => handleSelectOption(2, 'Price: Low to high')}
                            >
                                Price: Low to high
                            </button>
                            <button
                                className="text-black group flex w-full px-4 py-2 text-base font-medium"
                                onClick={() => handleSelectOption(3, '   Newest added')}
                            >
                                Newest added
                            </button>
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
}
